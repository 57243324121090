import React, { useEffect, useState } from "react";
import CouponList from "./CouponList";
import axios from "axios";
import endpoint from "../../helpers/Endpoint";
import { DatePicker } from "antd";
import CoupanModal from "./CoupanModal";
import moment from "moment";

const Index = () => {
  const [resList, setresList] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [totalDocs, settotalDocs] = useState();
  const [branchId, setbranchId] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isVisible, setisVisible] = useState(false);
  const [resList1, setresList1] = useState([]);
  const [date, setdate] = useState(new Date());
  const [couponType, setcouponType] = useState("All Coupons");
  let couponTypeList = ["All Coupons", "Expired", "Active"];
  const [isShowDateColumn, setisShowDateColumn] = useState(true);
  useEffect(() => {
    let a = JSON.parse(localStorage.getItem("resList"));
    console.log(a);
    a.unshift({ _id: "ALL", name: "ALL" });
    setresList(a);
    setbranchId(a[0]._id);
    getCouponValue(date, a[0]._id);
  }, []);

  useEffect(() => {
    console.log(" useEffect called");
    let a = JSON.parse(localStorage.getItem("resList"));
    console.log(a);
    a.unshift({ _id: "ALL", name: "ALL" });
    setresList(a);
    setbranchId(a[0]._id);
    getCouponValue(date, a[0]._id);
  }, [isVisible]);

    const onDateChange=(date, dateString)=> {
      setdate(date);
      getCouponValue(date,branchId);
    }
    const setDefaultDates = (date) => {
    let startDate = moment(date).set({
        hour: 4,
        minute: 0,
        second: 0
      })._d;
    let endDate = moment(date).add(1, "days").set({
        hour: 3,
        minute: 59,
        second: 59
      })._d;
      return { startDate, endDate };
    };
  const getCouponValue = async (date1=date,branchId, page = 1, pageSize = 10) => {
    try {
      setisLoading(true);
      const token = localStorage.getItem("token");
      const {startDate,endDate}=setDefaultDates(date1)
     
      
      const result = await axios.post(
        `${endpoint}/api/v1/admin/coupons/getallrescoupan`,
        {
          startDate,
          endDate,
          branchId,
          page,
          pageSize
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        }
      );
      let a = result.data.data.totalDocs;
      settotalDocs(a);
      setCouponData(result.data.data);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.log("error is", error);
    }
  };
  const getCouponWithType =async (couponType, branchId=branchId,page = 1, pageSize = 10)=>{
    try {
      setisLoading(true);
      const token = localStorage.getItem("token");
       const result = await axios.post(
    `${endpoint}/api/v1/admin/coupons/get_coupon_with_type`,
    {
      page,
      pageSize,
      couponType:couponType,
      branchId:branchId
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
    console.log("result",result)
    let a = result.data.data.totalDocs;
    settotalDocs(a);
    setCouponData(result.data.data);
    setisLoading(false);
    
  } catch (error) {
    setisLoading(false);
      
    }
  }
 
 const pageChanged =(date,branchId, page, pageSize,couponType)=>{

    if(couponType=="All Coupons"){
      getCouponValue(date,branchId, page, pageSize)
    }else{
      getCouponWithType(couponType,branchId,page,pageSize);
    }
    

 }
  const CouponTypeChanged =(event)=>{
     setcouponType(event.target.value);
    
     
    if(event.target.value =="All Coupons"){
      getCouponValue(date,branchId)
      setisShowDateColumn(true)

    }else{
      
      getCouponWithType(event.target.value,branchId)
      setisShowDateColumn(false)

    }
   

  };

  const resChanged = (event) => {

    setbranchId(event.target.value);
    console.log("couponType", couponType);
    console.log("branchId", branchId);
    if (couponType == "All Coupons") {
      getCouponValue(date, event.target.value);
    } else {
      getCouponWithType(couponType, event.target.value);
    }
    //  getCouponValue(date,event.target.value);
  };


  const addCouponClicked = () => {
    setisVisible(true);
  };
  const closeCoupanModal = () => {
    setisVisible(false);
  };

  const handleCanel = () => {
    setisVisible(false);
  };
  return (
    <div style={{ boxShadow: "0px 0px 4px 2px #dbdbdb",borderRadius:10}}>
      <div style={{ paddingBottom: "20px", display:"flex", justifyContent:"space-between", alignItems: "center", padding:25}}>
        <div style={{ display:"flex", justifyContent:"space-between", alignItems: "center"}}>
          <select onChange={resChanged}>
            {resList.map((item) => {
              return (
                <>
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                </>
              );
            })}
          </select>
          {isShowDateColumn && (
            <DatePicker
              onChange={onDateChange}
              format="YYYY-MM-DD"
              placeholder="Select day"
            />
          )}
          <select style={{ marginLeft: "1%" }} onChange={CouponTypeChanged}>
            {couponTypeList.map((item) => {
              return (
                <>
                  <option key={item} value={item}>
                    {item == "Active" ? "Active/Inactive" : item}
                  </option>
                </>
              );
            })}
          </select>
        </div>

        <button className="couponContainer" onClick={addCouponClicked}>
          Add Coupon
        </button>
      </div>

      <CoupanModal
        resList={resList}
        visible={isVisible}
        closeCoupanModal={closeCoupanModal}
        handleCanel={handleCanel}
      ></CoupanModal>

      <CouponList
        data={couponData}
        changePage={pageChanged}
        totalDocs={totalDocs}
        branchId={branchId}
        isLoading={isLoading}
        date={date}
        updateData={getCouponValue}
        updateDataCouponType={getCouponWithType}
        couponType={couponType}
      ></CouponList>
    </div>
  );
};

export default Index;
