import React, { useEffect, useState } from "react";
import endpoint from "../../../helpers/Endpoint";
import CustomPopupConfirm from "../../popups/popupconfirm";
import { Select, Switch } from "antd";
const Option = Select.Option;
const TaxesForm = (props) => {
  let isTaxStatus = true;
  const category = props.category;
  let errorObj = {
    taxNameError: "",
    taxCodeError: "",
    taxValueError: "",
    taxModuleError: ""
  };
  const modulesOptions = [
    { key: "onlineOrder", text: "Online Order" },
    { key: "takeAway", text: "Take Away" },
    { key: "driveOut", text: "Drive Out" },
    { key: "table", text: "Table" },
    { key: "noTable", text: "No Table" },
    { key: "driveOutCharge", text: "Drive Out Charge" },
    { key: "vendor", text: "vendor" },
    { key: "retailer", text: "retailer" },
  ];
  const [taxName, settaxName] = useState("");
  const [taxValue, settaxValue] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [taxStatus, settaxStatus] = useState("active");
  const [branchTaxesArray, setBranchTaxesArray] = useState([]);
  const [branchName, setbranchName] = useState("");
  const [branchId, setBranchId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isPopup, setisPopup] = useState(false);
  const [taxArrayId, settextArrayId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    getBranchTaxes();
  }, []);

  function getBranchTaxes() {
    const id = props.match.params.slug;
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    setIsLoaded(true);
    fetch(`${endpoint}/api/v1/admin/branch/getBranchTaxes`, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result) {
          setBranchTaxesArray(result.data.taxes);
          setbranchName(result.data.name);
          setBranchId(result.data._id);
        }
        setIsLoaded(false);
      })
      .catch((error) => console.log(error));
    setIsLoaded(false);
  }

  const [errors, setErrors] = useState(errorObj);
  const [isLoaded, setIsLoaded] = useState(false);
  function handleErrors(result) {
    if (!result.ok) {
      throw Error(result.status);
    }
    return result;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let isError = false;
    if (taxName.trim() == "") {
      isError = true;
      errorObj.taxNameError = "Tax Name is required";
    }
    if (taxValue.trim() == "" || isNaN(taxValue)) {
      isError = true;
      errorObj.taxValueError = "Tax Value is required";
    }
    if (taxCode.trim() == "" || isNaN(taxCode)) {
      isError = true;
      errorObj.taxCodeError = "Avg.Kode is required";
    }
    if (modules.length == 0) {
      isError = true;
      errorObj.taxModuleError = "Tax Modules is required";
    }
    if (isError) {
      setErrors(errorObj);
    } else {
      saveBranchTaxes();
    }
  };

  const showConfirmationDialog = (taxArrayId) => {
    setisPopup(true);
    settextArrayId(taxArrayId);
  };

  const formPage = () => {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div
          className="row"
          style={{ width: "50%", margin: "auto", height: "70%" }}
        >
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span
                    className="icon"
                    onClick={() => {
                      setIsAdd(false);
                    }}
                  >
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <span>
                    {branchName} :{isEdit ? "Update Tax" : "Add Tax"}
                  </span>
                </span>
              </h3>
              <div className="row " style={{ padding: "5%" }}>
                <div className="col-12">
                  <form onSubmit={handleSubmit} className="form-horizontal box">
                    <div className="field">
                      <label className="label">Tax Name</label>
                      <div className="control">
                        <div className="field">
                          <input
                            className="input"
                            type="text"
                            name="name"
                            placeholder="Enter Tax Name"
                            value={taxName}
                            onChange={(e) => settaxName(e.target.value)}
                          />
                        </div>
                      </div>
                      <p className="help is-danger">{errors.taxNameError}</p>
                    </div>
                    <div className="field">
                      <label className="label">Avg.Kode</label>
                      <div className="control">
                        <div className="field">
                          <input
                            className="input"
                            type="text"
                            name="name"
                            placeholder="Enter Avg.Kode"
                            value={taxCode}
                            onChange={(e) => setTaxCode(e.target.value)}
                          />
                        </div>
                      </div>
                      <p className="help is-danger">{errors.taxCodeError}</p>
                    </div>
                    <div className="field">
                      <label className="label">Tax Value</label>
                      <div className="control">
                        <div className="field">
                          <input
                            className="input"
                            type="text"
                            name="name"
                            placeholder="Enter Tax Value in %"
                            value={taxValue}
                            onChange={(e) => settaxValue(e.target.value)}
                          />
                        </div>
                      </div>
                      <p className="help is-danger">{errors.taxValueError}</p>
                    </div>
                    <div className="field">
                      <label className="label">Tax Modules</label>
                      <div className="control">
                        <div className="field">
                          <Select
                            name="modules"
                            value={modules}
                            onChange={(e) => setModules(e)}
                            mode="multiple"
                          >
                            {modulesOptions.map((ele) => {
                              return (
                                <Option key={ele.key} value={ele.key}>
                                  {ele.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <p className="help is-danger">{errors.taxModuleError}</p>
                    </div>
                    <button
                      type="submit"
                      style={{ width: "100%" }}
                      className="button is-primary"
                    >
                      {isEdit ? "Update" : "Save"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeTaxStatus = (checked, id) => {
    const url = `${endpoint}/api/v1/admin/branch/updateBranchTaxStatus`;
    const token = localStorage.getItem("token");
    const data = JSON.stringify({
      taxStatus: checked,
      id: branchId,
      taxArrayId: id
    });

    fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        getBranchTaxes();
      })
      .catch((error) => console.log(error));
  };

  const onUpdateTaxes = (taxId, taxName, taxValue, taxCode, taxModules) => {
    errorObj = {
      taxNameError: "",
      taxCodeError: "",
      taxValueError: ""
    };
    setErrors(errorObj);

    setIsEdit(true);
    setIsAdd(true);
    setTaxCode(taxCode);
    settaxName(taxName);
    settaxValue(taxValue);
    settextArrayId(taxId);
    setModules(taxModules);
  };

  const updateTaxesOnweb = () => {
    const url = `${endpoint}/api/v1/admin/branch/updateBranchTaxes`;
    const token = localStorage.getItem("token");
    const data = JSON.stringify({
      taxName,
      taxCode,
      taxValue,
      id: branchId,
      taxArrayId: taxArrayId,
      modules
    });

    fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        getBranchTaxes();
        setIsAdd(false);
        setIsEdit(false);
      })
      .catch((error) => console.log(error));
  };

  const tablePage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => props.history.goBack()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                </span>
                {branchName} : Taxes
              </h3>
                <button
                  className="button is-success"
                  onClick={() => {
                    errorObj = {
                      taxNameError: "",
                      taxCodeError: "",
                      taxValueError: ""
                    };
                    setErrors(errorObj);

                    setIsAdd(true);
                    setIsEdit(false);

                    settaxName("");
                    settaxValue("");
                    setTaxCode("");
                    setModules([]);
                  }}
                >
                  <i className="fa fa-plus"></i>&nbsp;{"ADD TAX"}
                </button>
              </div>
              <div className="row">
                <div className="column">
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>TaxName</th>
                        <th>TaxValue</th>
                        <th>Avg.Kode</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {branchTaxesArray.length > 0 ? (
                        branchTaxesArray.map((item, idx) => {
                          return (
                            <tr key={item._id}>
                              <td>{idx + 1}</td>
                              <td>{item.taxName}</td>
                              <td>{item.taxValue}</td>

                              <td>{item.taxCode}</td>
                              <td>
                                <Switch
                                  defaultChecked
                                  checked={item.status == "active"}
                                  onChange={(checked) =>
                                    onChangeTaxStatus(checked, item._id)
                                  }
                                />
                              </td>
                              <td>
                                <button
                                  className="button is-primary"
                                  onClick={() => {
                                    onUpdateTaxes(
                                      item._id,
                                      item.taxName,
                                      item.taxValue,
                                      item.taxCode,
                                      item.taxModules ? item.taxModules : []
                                    );
                                  }}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                <button
                                  className="button is-danger"
                                  onClick={() => {
                                    showConfirmationDialog(item._id);
                                  }}
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const saveBranchTaxes = () => {
    const url = isEdit
      ? `${endpoint}/api/v1/admin/branch/updateBranchTaxes`
      : `${endpoint}/api/v1/admin/branch/saveBranchTaxes`;
    const token = localStorage.getItem("token");
    const data = {
      taxName,
      taxCode,
      taxValue,
      id: branchId,
      taxStatus,
      modules
    };
    if (isEdit && taxArrayId) {
      data.taxArrayId = taxArrayId;
    }
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        }
        settaxName("");
        settaxValue("");
        setTaxCode("");
        getBranchTaxes();
        errorObj = {
          taxNameError: "",
          taxCodeError: "",
          taxValueError: ""
        };
        setErrors(errorObj);
        setIsAdd(false);
      })
      .catch((error) => console.log(error));
  };

  const deleteTax = () => {
    const url = `${endpoint}/api/v1/admin/branch/deleteBranchTaxes`;
    const token = localStorage.getItem("token");
    const data = JSON.stringify({
      arrayId: taxArrayId,
      id: branchId
    });

    fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        getBranchTaxes();

        setisPopup(false);
      })
      .catch((error) => console.log(error));
  };
  const cancelConformationDialog = () => {
    setisPopup(false);
  };

  return (
    <React.Fragment>
      {isAdd ? formPage() : tablePage()}
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        action={deleteTax}
      />
    </React.Fragment>
  );
};
export default TaxesForm;
