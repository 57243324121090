import React from "react";

const Error404 = () => {
  return (
    <div>
      <p className="is-size-4 has-text-danger has-text-weight-bold">
        Error 404! Page Not Found
      </p>
    </div>
  );
};

export default Error404;
