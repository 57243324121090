import React, { useEffect, useState } from "react";

// import { sendNotification } from "../../helpers/commFuc";
import { Modal, Button, Input } from "antd";
import { Select } from "antd";
import axios from "axios";
import endpoint from "../../helpers/Endpoint";
import { notification } from "antd";
import Notification from "react-bulma-notification";
import countryCodes from "country-codes-list";

const CoupanModal = (props) => {
  const [visible, setVisible] = useState(props.visible);
  const [coupanValue, setCoupanValue] = useState();
  const [phoneNumber, setphoneNumber] = useState();
  const { Option } = Select;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [branchArray, setbranchArray] = useState([]);
  const [branchArrayMap, setbranchArrayMap] = useState([]);
  const [resList, setresList] = useState([]);
  const [monthsToExpire, setmonthsToExpire] = useState(3);
  const [countryCode, setcountryCode] = useState(47);
  let options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const myCountryCodesObject = countryCodes.customList(
    "countryCode",
    "[{countryCode}]:{countryCallingCode}"
  );
  const allCountryCodes = Object.values(myCountryCodesObject);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Notification Title",
      description: "Please fill all the fields"
    });
  };
  useEffect(() => {
    setresList([]);
  }, []);

  useEffect(() => {
    setresList(props.resList);
  }, [props.resList]);

  const showModal = () => {
    setVisible(true);
  };

  const resSelected = (e) => {
    console.log(e);
    if (e.length > 0) {
      if (e.includes("ALL")) {
        setresList([props.resList[0]]);
      } else {
        setresList(props.resList.filter((item) => item._id !== "ALL"));
      }
    } else {
      setresList(props.resList);
    }

    if (e.includes("ALL")) {
      let a = JSON.parse(localStorage.getItem("resList"));
      let b = a.map((item) => {
        return item._id;
      });
      console.log("aaaaaaaaaa", b);
      setbranchArray(b);
    } else {
      setbranchArray(e);
    }
  };

  const saveCoupon = async (
    branchId,
    coupanValue,
    phoneNumber,
    monthsToExpire
  ) => {
    try {
      const token = localStorage.getItem("token");
      let fullPhoneNumber = countryCode + "" + phoneNumber;
      const result = await axios.post(
        `${endpoint}/api/v1/admin/coupons/saveadmincoupan`,
        {
          branchId,
          coupanValue,
          phoneNumber: fullPhoneNumber,
          monthsToExpire
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        }
      );
      console.log("saved coupun is", result);
      openNotification("success", result.data.message);
    } catch (error) {
      console.log("error is", error);
      openNotification("error", error.message);
    }
  };
  const handleOk = async () => {
    if (branchArray.length > 0 && coupanValue && phoneNumber) {
      await saveCoupon(branchArray, coupanValue, phoneNumber, monthsToExpire);

      setresList([]);
      setCoupanValue("");
      setphoneNumber("");
      props.closeCoupanModal();
    } else {
      // alert("please fill all the fields")
      openNotificationWithIcon("warning");
    }
  };
  const countryCodeSelected = (e) => {
    console.log("dfsdf", e);
    let code = e.split(":")[1];
    setcountryCode(code);
  };

  const handleCancel = () => {
    setresList([]);
    setCoupanValue("");
    setphoneNumber("");
    props.handleCanel();
  };
  const phoneNumberSelected = (e) => {
    console.log(e.target.value);
    console.log(countryCode);

    // setphoneNumber(countryCode+''+e.target.value)
    setphoneNumber(e.target.value);
    console.log("phone", phoneNumber);
  };
  const openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 3 });
  };

  return (
    <>
      <div>
        <Modal
          title="ADD COUPON"
          visible={props.visible}
          onOk={handleOk}
          centered
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <div style={{ width: "100%" }}>
            <form className="form-horizontal box">
              <div className="field">
                <label className="label">Coupon Value</label>
                <div className="control">
                  <div className="field">
                    <input
                      required={true}
                      className="input"
                      type="number"
                      name="couponVlaue"
                      placeholder="Enter Coupon Value"
                      value={coupanValue}
                      onChange={(e) => setCoupanValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div
                  className="field"
                  style={{ marginRight: "1%", width: "30%" }}
                >
                  <label className="label">Country Code</label>
                  <div className="control">
                    <div style={{ width: "100%" }} className="select">
                      <Select
                        required
                        name="country-code"
                        placeholder="Please select"
                        style={{ width: "100%" }}
                        defaultValue={47}
                        // value={getTaxValues(taxType)}
                        onChange={(e) => countryCodeSelected(e)}
                      >
                        {allCountryCodes &&
                          allCountryCodes.map((item) => {
                            return (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            );
                          })}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="field" style={{ width: "70%" }}>
                  <label className="label">Phone Number</label>
                  <div className="control">
                    <div className="field">
                      <input
                        required={true}
                        className="input"
                        type="number"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(e) => phoneNumberSelected(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Months to expire</label>
                <div className="control">
                  <div style={{ width: "100%" }} className="select">
                    <Select
                      required
                      name="month"
                      placeholder="Please select"
                      style={{ width: "100%" }}
                      defaultValue={3}
                      // value={getTaxValues(taxType)}
                      onChange={(e) => setmonthsToExpire(e)}
                    >
                      {options &&
                        options.map((item) => {
                          return (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Restaurants</label>
                <div className="control">
                  <div style={{ width: "100%" }} className="select">
                    <Select
                      required
                      name="resType"
                      mode="multiple"
                      placeholder="Please select"
                      style={{ width: "100%" }}
                      // value={getTaxValues(taxType)}
                      onChange={(e) => resSelected(e)}
                    >
                      {resList &&
                        resList.map((item) => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default CoupanModal;
