import React, { Component, Fragment } from "react";
import endpoint from "../../helpers/Endpoint";
import { Icon, Badge } from "antd";
import Axios from "axios";

export default class ListDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      currentPage: "deviceList",
      devicesData: [],
      style: "",
      deleteLoading: false,
      currentDeleteId: ""
    };
  }
  componentDidMount() {
    this.getdevices();
  }
  openModal = (id) => {
    this.setState({ currentDeleteId: id });
    this.setState({ activeDelete: id, style: "is-active" });
  };

  handleChange = (value, index) => {
    this.state.devicesData[index].terminalId = value;
    let obj = this.state.devicesData;
    this.setState({ devicesData: obj });
  };

  saveTerminalId = async (_id, terminalId) => {
    await Axios.put(
      `${endpoint}/admin/edit-device`,
      { _id, terminalId },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token")
        }
      }
    );
  };

  closeModal = () => {
    this.setState({ style: "" });
  };
  getdevices = () => {
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    const token = localStorage.getItem("token");
    fetch(`${endpoint}/admin/getdevice`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result.data);
        } else {
          const data = result.data.filter((item) => !item.isDeleted);
          this.setState({ devicesData: data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };

  deleteDevice = () => {
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    const token = localStorage.getItem("token");
    var postData = {
      id: this.state.currentDeleteId
    };
    fetch(`${endpoint}/admin/deletedevice`, {
      method: "PUT",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result.data);
        } else {
          this.setState({ style: "" });
          this.getdevices();
          const data = result.data.filter((item) => !item.isDeleted);
          this.setState({ devicesData: data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };
  render() {
    var { devicesData, style, currentPage, isLoaded } = this.state;
    devicesData.sort((a,b)=>a.modelNumber>b.modelNumber?-1:1)
    if (!isLoaded) {
      return "Loading...";
    } else if (currentPage === "deviceList") {
      return (
        <div
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",borderRadius:10
          }}
          id="machineList"
        >
          <div className="columns is-centered">
            <table bordered className="table is-fullwidth">
              <thead>
                <tr>
                  <th> Machine No.</th>
                  <th>Device ID</th>
                  <th>Model No.</th>
                  <th>Restaurant</th>
                  <th>Status</th>
                  <th>Terminal Id</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {devicesData.map((item, index) => {
                  return (
                    <tr key={item._id}>
                      <td>POS-{item.deviceSerialNumber}</td>
                      <td>{item.deviceId}</td>
                      <td>{item.modelNumber}</td>
                      <td>{item.branchId && item.branchId.name}</td>
                      <td>
                        {item.status == "IN-USE" ? (
                          <Badge status="success" />
                        ) : (
                          <Badge status="Warning" />
                        )}
                        {item.status}
                      </td>
                      <td style={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
                        <input
                          style={{ width: "70%"}}
                          className="input"
                          type="text"
                          name="range1Min"
                          placeholder="Enter Terminal Id"
                          value={item.terminalId}
                        onChange={(e) => this.handleChange(e.target.value, index)}
                        />

                        <button
                          className="button is-primary"
                          onClick={() =>
                            this.saveTerminalId(item._id, item.terminalId)
                          }
                        >
                          Save
                        </button>
                      </td>

                      {item.status !== "IN-USE" ? (
                        <td>
                          <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                            <button
                              className="button is-danger"
                              onClick={() => {
                                this.openModal(item._id);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      ) : <td></td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className={`modal ${style}`}>
            <div className="modal-background" />
            <div className="modal-content" style={{ zIndex: 1 }}>
              <div className="tile is-parent">
                <article className="tile is-child notification ">
                  <div className="content">
                    <p>Are you sure you want to delete this Device?</p>
                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={this.deleteDevice}
                      >
                        Yes
                      </button>
                      <button className="button" onClick={this.closeModal}>
                        No
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div
              onClick={this.closeModal}
              style={{
                minWidth: "100%",
                minHeight: "100%",
                position: "absolute"
              }}
            >
              <button className="modal-close is-large" aria-label="close" />
            </div>
          </div>
        </div>
      );
    }
  }
}
