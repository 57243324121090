import React, { useEffect, useState } from "react";
import endpoint from "../../../helpers/Endpoint";
import CustomPopupConfirm from "../../popups/popupconfirm";
import Notification from "react-bulma-notification";

const VerifoneList = (props) => {
  let branchMachine =
    props.machine && props.machine.length > 0 ? props.machine : [];
  let [isPopup, setisPopup] = useState(false);
  let [machineId, setMachineId] = useState("0");
  let deleteverifoneMachine = (id) => {
    setisPopup(true);
    const token = localStorage.getItem("token");
    fetch(`${endpoint}/api/v1/admin/verifone/deleteverifoneMachine`, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result.status == 200) {
          props.getAllMachine();
          openNotification("success", "deleted sucessfully");
        } else {
          openNotification("error", result.message);
        }
        setisPopup(false);
      })
      .catch((error) => {
        setisPopup(false);
      });
  };

  let openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  let cancelConformationDialog = () => {
    setisPopup(false);
  };
  return (
    <>
      <div className="row">
        <div className="card">
          <div className="card-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 className="card-title">
              <span class="icon-text">
                <span class="icon" onClick={() => props.history.goBack()}>
                  <i class="fas fa-arrow-left"></i>
                </span>
              </span>
              {props.slug}: Verifone Machine
            </h3>
              <button
                className="button is-success"
                onClick={() => {
                  props.pageChangeHandler(false, false, null);
                }}
              >
                <i className="fa fa-plus"></i>&nbsp;{"ADD MACHINE"}
              </button>
            </div>
            <div className="row">
              <div className="column">
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Machine Serial.No</th>
                      <th>Machine IP</th>
                      <th>Linked Device </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {branchMachine.length > 0 ? (
                      branchMachine.map((sb, index) => {
                        return (
                          <tr key={sb._id}>
                            <td>{index + 1}</td>
                            <td>{sb.verifoneSerialNumber}</td>
                            <td>{sb.verifoneIp}</td>

                            <td>{sb.deviceId}</td>

                            <td>
                              <button
                                className="button is-primary"
                                onClick={() => {
                                  props.pageChangeHandler(false, true, sb);
                                }}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="button is-danger"
                                style={{ marginLeft: "5px" }}
                                onClick={() => {
                                  setisPopup(true);
                                  setMachineId(sb._id);
                                }}
                              >
                                <i className="fa fa-trash" />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <p>No data found</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        action={deleteverifoneMachine}
        productId={machineId}
      />
    </>
  );
};

export default VerifoneList;
