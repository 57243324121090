import React, { Fragment ,useEffect,useState} from "react";
import Header from "../header/Header";
import endpoint from "../../helpers/Endpoint";
import moment from "moment";
const Dashboard = (props) => {
  // let weeklyCancelReportCount=0;
  // useEffect(()=>{
  //   console.log("first render in useEffect======>");
  //   getRestReports();
  // },[]);
  // let getRestReports = (date) => {
  //   var hdr = localStorage.token;
  //   // var branchId = this.props.match.params.id;
  //   var postData = JSON.stringify({
  //     startDate: moment(date).set({
  //       hour: 4,
  //       minute: 0,
  //       second: 0
  //     }),
  //     endDate: moment(date).add(1, "days").set({
  //       hour: 3,
  //       minute: 59,
  //       second: 59
  //     }),
  //     // branchId: branchId,
  //     isMonth: false
  //   });
  //   fetch(`${endpoint}/admin/singleadminreportweeklycancelled`, {
  //     method: "post",
  //     body: postData,
  //     headers: {
  //       "x-auth-token": hdr,
  //       "Content-Type": "application/json"
  //     }
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       // weeklyCancelReportCount=result.data.length;
  //       console.log("result in dashboard in weeklycancelledreport=====>",result);
  //       if(result.status==404){
  //         weeklyCancelReportCount=0;
  //       }else{
  //         weeklyCancelReportCount=result.data.length;
  //       }
  //       localStorage.setItem("weeklyCancelReportCount", weeklyCancelReportCount);
  //       // this.setState({ reportspdf: result.data });
  //     });
  // };
  return (
    <Fragment>
      <Header
        loggedIn={true}
        logOut={props.logOut}
        changePassword={props.changePassword}
      />
    </Fragment>
  );
};

export default Dashboard;
