import React from "react";
import { NavLink, generatePath } from "react-router-dom";

import Notification from "react-bulma-notification";
import endpoint from "../../../helpers/Endpoint";
import s3url from "../../../helpers/s3";

import ReactTable from "react-table";
import "react-table/react-table.css";

class ListAdminIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      pages: 0,
      pageSize: 10,

      activeDeleteId: null,
      style: ""
    };

    this.fetchData = this.fetchData.bind();
    this.deleteAdminIcon = this.deleteAdminIcon.bind();
  }

  fetchData = async (state) => {
    try {
      let result = await fetch(`${endpoint}/api/v1/admin/adminIcons/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token")
        },
        body: JSON.stringify({
          pageSize: state.pageSize,
          page: state.page
        })
      }).then((result) => result.json());

      if (result.success === -1) {
        // Not Logged In
        this.openNotification("error", "Please login again to continue");
        this.props.history.push("/");
        return;
      } // Not Logged In

      if (result.success === 0) {
        this.openNotification("error", result.message);
        return;
      }

      this.setState({
        loading: false,
        data: result.data.data,
        pages: result.data.pages
      });
    } catch (error) {
      this.setState({
        loading: false,
        data: [],
        pages: 0
      });
      this.openNotification("error", error.message);
    }
  };

  openDeleteModal = (id) => {
    this.setState({ activeDeleteId: id, style: "is-active" });
  };

  closeModal = () => {
    this.setState({ style: "" });
  };

  deleteAdminIcon = async () => {
    try {
      let result = await fetch(`${endpoint}/api/v1/admin/adminIcons/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token")
        },
        body: JSON.stringify({
          id: this.state.activeDeleteId
        })
      }).then((result) => result.json());

      if (result.success === -1) {
        // Not Logged In
        this.openNotification("error", "Please login again to continue");
        this.props.history.push("/");
        return;
      } // Not Logged In

      if (result.success === 0) {
        this.openNotification("error", result.message);
        return;
      }

      this.closeModal();

      this.openNotification("success", result.message);
      this.fetchData(this.state);
    } catch (error) {
      this.openNotification("error", error.message);
    }
  };

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  render() {
    const columns = [
      {
        Header: "Id",
        id: "row",
        filterable: false,
        sortable: false,
        width: "15%",
        Cell: (row) => {
          return <center>{row.index + 1}</center>;
        }
      },
      {
        Header: "Name",
        id: "name",
        filterable: false,
        sortable: false,
        Cell: (d) => {
          return (
              <strong>{d.original.name}</strong>
          );
        }
      },
      {
        Header: "Icon",
        id: "Icon",
        filterable: false,
        sortable: false,
        Cell: (d) => {
          return (
              <div
                className="image-icon-box justify-content-center d-flex"
                key={d.original._id}
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  marginLeft: "0px",
                  marginRight: "0px"
                }}
              >
                <img
                  src={`${s3url}/${d.original.path}`}
                  className="image-icon"
                  alt={d.original.name}
                  title={d.original.name}
                  style={{
                    width:"50px"
                  }}
                />
              </div>
          );
        }
      },
      {
        Header: "Action",
        id: "Update",
        filterable: false,
        sortable: false,
        style:{
          justifyContent: "space-around",
        },
        Cell: (d) => {
          return (
            <>
              <NavLink
                title={`Edit ${d.original.name}`}
                className="button is-primary"
                to={generatePath("/adminIcons/:id/update", {
                  id: d.original._id
                })}
                exact={true}
              >
                Edit Icon
              </NavLink>
              <a
                title={`Delete ${d.original.name}`}
                className="button is-danger"
                onClick={() => this.openDeleteModal(d.original._id)} >
                Delete Icon
              </a>
            </>
          );
        }
      }
    ];

    return (
      <div className="tile is-parent admin-icons">
        <article className="tile is-child notification ">
          <ReactTable
            columns={columns}
            manual
            data={this.state.data}
            pages={this.state.pages} // Display the total number of pages
            loading={this.state.loading} // Display the loading overlay when we need it
            onFetchData={this.fetchData} // Request new data when things change
            filterable
            defaultPageSize={10}
            className="-striped -highlight"
            // showPaginationTop={true}
            // showPaginationBottom={false}
            multiSort={false}
          />

          <div className={`modal ${this.state.style}`}>
            <div className="modal-background" />
            <div className="modal-content" style={{ zIndex: 1 }}>
              <div className="tile is-parent">
                <article className="tile is-child notification ">
                  <div className="content">
                    <p>Are you sure! You want to delete this icon?</p>

                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={this.deleteAdminIcon}
                      >
                        Yes
                      </button>
                      {"  "}
                      <button className="button" onClick={this.closeModal}>
                        No
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div
              onClick={this.closeModal}
              style={{
                minWidth: "100%",
                minHeight: "100%",
                position: "absolute"
              }}
            >
              <button className="modal-close is-large" aria-label="close" />
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default ListAdminIcons;
