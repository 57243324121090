import React, { useState,useEffect } from 'react';
import axios from 'axios';
import endpoint from "../../helpers/Endpoint";
import moment from "moment";
import { Table } from 'antd';
import { Select } from 'antd';
import  Column from "./dataColumn.js";
import TableData from './TableData';


// const { Column, ColumnGroup } = Table;

const Index=()=>{

    console.log("inside index of InprocessInvoices----->")
    let [inProcessInvoices,setInProcessInvoices]=useState([]);
    let [isLoading,setIsLoading]=useState(false);
    let [date,setDate]=useState(new Date());
    let [selectedRestaurantId,setSelectedRestaurantId]=useState(null);
    let [reload,setReload]=useState(false);
    const { Option } = Select
    useEffect(()=>{
        getAllInProcessInvoices();
    },[]);
    useEffect(()=>{
      console.log("selected Restaurant id---->",selectedRestaurantId)
      getAllInProcessInvoices();
  },[selectedRestaurantId,reload]);


    const onDateChange=(date, dateString)=> {
        setDate(date);
        
      }
      const setDefaultDates = (date) => {
        const startDate = moment(date).set({
          hour: 4,
          minute: 0,
          second: 0
        })._d;
        const endDate = moment(date).add(1, "days").set({
          hour: 3,
          minute: 59,
          second: 59
        })._d;
        return { startDate, endDate };
      };
    
    const getAllInProcessInvoices=async(date1=date)=>{
        try{
            setIsLoading(true);
            const token = localStorage.getItem("token");
            let {startDate, endDate}=setDefaultDates(date1);
            console.log("inside the getAllInProcessInvoices selectedRestaurantId--->",selectedRestaurantId)
            const result = await axios.post(
                `${endpoint}/api/v1/admin/inprocessinvoices/getunpaidcartinvoice`,
                {
                  startDate,
                  endDate,
                  selectedRestaurantId
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": token
                  }
                }
              );
              setInProcessInvoices(result.data.data.invoiceData);
              setIsLoading(false);
            // console.log("result of inprocessinvoices---->", result.data.data.invoiceData);
            // console.log("inprocessInvoices-------->",inProcessInvoices)
            
        }catch(error){
            setIsLoading(false);
            console.log("error is", error);

        }
    }
    const handleSelectChange=(value)=>{
      // console.log("inside handleSelectChange------>",value);
      setSelectedRestaurantId(value);
    }
   let resList=localStorage.getItem("resList");
   resList=JSON.parse(resList);
   console.log("resList------>",resList.length);
   let restaurant=resList.map(itm=>{
     let obj={}
     obj.name=itm.name;
     obj.branchId=itm._id;
     obj.slug=itm.slug;
     return obj;
   })
   console.log("restaurant----->",restaurant);
   console.log("inprocessInvoices-------->",inProcessInvoices);
   const options=[];
   for (let i =0; i < restaurant.length; i++) {
    options.push(<Option key={i} value={restaurant[i].branchId}>{restaurant[i].name}</Option>);
  }
  options.push(<Option key={restaurant.length+1} value={null}>All Restaurants</Option>);
    return(
        <div style={{ boxShadow: "0px 0px 4px 2px #dbdbdb",borderRadius:10 }}>
      <Select  placeholder="Please Select Restaurant" onChange={handleSelectChange} style={{ width: 200 }}>
        {options}
      </Select>
        <TableData bordered setReload={setReload} reload={reload} isLoading={isLoading} inProcessInvoices={inProcessInvoices}></TableData>
        </div>
    )
}

export default Index;