import { render } from "react-dom";
import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Modal, Button } from "antd";

const CustomPopupConfirm = (props) => {
  const handleOk = () => {
    props.action(props.productId);
  };

  const handleCancel = () => {
    props.cancel();
  };

  return (
    <Modal
      title="Confirmation"
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p><bold>Are you sure you want to delete this?</bold></p>
    </Modal>
  );
};
export default CustomPopupConfirm;
