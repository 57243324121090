import React from 'react'
import { useEffect ,useState} from 'react'
import axios from "axios";
import { Button, Table,Tag } from "antd";
import moment from "moment"

import endpoint from "../../helpers/Endpoint";

const { Column, ColumnGroup, } = Table;



function DepositHistory(props) {
  let pageSize=15
    const setPage=async(type,val)=>{
    let page=currentPage
    if(type=="next"){
        page+=val
        await setCurrentPage(Number(page))
    }  
    if(type=="prev"){
        page+=-val
       await setCurrentPage(Number(page))
    } 
    
    let token=localStorage.getItem("token")
    console.log("page--------",{
      page,
      currentPage
    })
      getDepositData(id,token,page)
    }


    const getDepositData = async (branchId,token,page) => {
        const res = await axios.post(`${endpoint}/admin/depositHistory`,
        { branchId ,pageNo:page,pageSize},
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token" : token
          },
        }).then(
            res=>{setDepositData(res.data.data)

             
              
             

              SetTotal(res.data.count)
                console.log("-----res.data-------",res.data)
                console.log("-----res.data.count------",res.data.count)
               
            }
           
        ).catch(err=>console.log("------Errorr-------",err))
       
    }
const [depositData,setDepositData]=useState([])
const [branchName,setBranchName]=useState("")
const [currentPage,setCurrentPage]=useState(1)
const [id,SetId]=useState("")
const [total,SetTotal]=useState(0)


    useEffect(()=>{
        
       
        console.log("props Data id",props.location)
        let branchId=props.location.state.id
        SetId(branchId)
        setBranchName(props.location.state.branchName)
        let token=localStorage.getItem("token")
        getDepositData(branchId,token,currentPage)
        
    },[])



  return (
    <>
    <div> {branchName} Deposit History</div>
    <Table  dataSource={depositData} pagination={false} >
      <Column title="Date" dataIndex="createdAt" key="createdAt" render={(record)=>{
      return <Tag bordered={false} color='blue'>{moment(new Date (record)).format('MMMM Do YYYY, h:mm:ss a')}</Tag>
      }}
      
      />
      <Column title="Deposit Type" dataIndex="transactionType" key="transactionType" render={(record)=>{
        if(record=="debit"){
          return <Tag bordered={false} color='red'>{record}</Tag>
        }else{
          return <Tag bordered={false} color='green'>{record}</Tag>
        }
     
      }}
      
      />

<Column title="Rem Before Deposit" dataIndex="remBefore" key="remBefore"  render={(record)=>{
      return <Tag  color='pink' bordered={false}>{record?record:"-"}</Tag>
      }}/>
      <Column title="Deposit Amount" dataIndex="amount" key="amount"  render={(record)=>{
      return <Tag bordered={false} color='green'>{record}</Tag>
      }} />
     
      <Column title="Rem After Deposit" dataIndex="remAfter" key="remAfter"  render={(record)=>{
        if(record<0) return <Tag color='#E83A59'>{record?record:"-"}</Tag>
        return <Tag  bordered={false} >{record}</Tag>
      }} />
      
    </Table>



{/* Pagination Code  */}

    <div >
    <Button disabled={currentPage==1?true:false} onClick={()=>(setPage("prev",1))}><>Prev</></Button>
   
    <Button ><>{currentPage }</></Button>

   
    <Button disabled={pageSize*Number(currentPage)>= total?true:false} onClick={()=>(setPage("next",1))} ><>Next</></Button>
    </div>

  {/* Pagination Code Ends */}
  </>
  )
}

export default DepositHistory