import React, { useEffect, useState } from "react";
import FormPage from "./verifoneform";
import VerifoneList from "./verifonelist";
import endpoint from "../../../helpers/Endpoint";

const VerifoneComponent = (props) => {
  let [isList, setIsList] = useState(true);
  const [branch, setBranch] = useState({});
  const [page, setPage] = useState("list");
  const [isLoaded, setIsLoaded] = useState(false);
  const [machineList, setAllMachine] = useState();
  const [isPopup, setPopup] = useState(false);
  const [singleMachine, setsingleMachine] = useState();
  const [isEdit, setisEdit] = useState();
  let slug = props.match.params.slug;
  let changePage = (page, isEdit, sinMachine) => {
    console.log(page, isEdit, sinMachine);
    if (page) {
      getAllMachine();
    }
    setIsList(page);
    setisEdit(isEdit);
    setsingleMachine(sinMachine);
  };

  useEffect(() => {
    getAllMachine();
  }, []);

  useEffect(() => {
    getBranch();
  }, []);
  const getBranch = () => {
    const slug = props.match.params.slug;
    const token = localStorage.getItem("token");

    fetch(`${endpoint}/api/v1/admin/branch/getbranch`, {
      method: "POST",
      body: JSON.stringify({ id: slug }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result) {
          console.log(result.data.branch._id);

          setBranch(result.data);
        }
      })
      .catch((error) => console.log(error));
  };

  const getAllMachine = () => {
    const slug = props.match.params.slug;
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    setIsLoaded(true);
    fetch(`${endpoint}/api/v1/admin/verifone/getVerifoneMachine`, {
      method: "POST",
      body: JSON.stringify({ id: slug }),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result) {
          console.log(result.data);
          setAllMachine(result.data);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      {isList && (
        <VerifoneList
          pageChangeHandler={changePage}
          branch={branch.branch}
          machine={machineList}
          history={props.history}
          getAllMachine={getAllMachine}
          slug={slug}
        ></VerifoneList>
      )}
      {!isList && (
        <FormPage
          pageChangeHandler={changePage}
          branchId={branch.branch._id}
          devices={branch.branch.devices}
          getAllMachine={getAllMachine}
          singleMachine={singleMachine}
          isEdit={isEdit}
          slug={slug}
        ></FormPage>
      )}
    </React.Fragment>
  );
};
export default VerifoneComponent;
