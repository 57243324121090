import React, { Component } from "react";
import endpoint from "../../helpers/Endpoint";
import {
  Row,
  Col,
  Button,
  DatePicker,
  PageHeader,
  Popover,
  notification
} from "antd";
import moment from "moment";
import unpaidimg from "../../assets/Paid.77d24e64.png";
const { MonthPicker } = DatePicker;

export default class RestReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportspdf: [],
      selectedDate: new Date(),
      monthlyReportDate: ""
    };
  }

  componentDidMount() {
    let date = moment().date()._d;
    this.getRestReports();
  }
  getRestReports = (date) => {
    var hdr = localStorage.token;
    var branchId = this.props.match.params.id;
    var postData = JSON.stringify({
      startDate: moment(date).set({
        hour: 4,
        minute: 0,
        second: 0
      }),
      endDate: moment(date).add(1, "days").set({
        hour: 3,
        minute: 59,
        second: 59
      }),
      branchId: branchId,
      isMonth: false
    });
    fetch(`${endpoint}/admin/singlerestreport`, {
      method: "post",
      body: postData,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ reportspdf: result.data });
      });
  };
  chkdt = (dt) => {
    var date = moment(dt).startOf("month").add(1, "month");
    var hdr = localStorage.token;
    var branchId = this.props.match.params.id;
    var postData = JSON.stringify({
      startDate: moment(date).set({
        hour: 4,
        minute: 0,
        second: 0
      }),
      endDate: moment(date).add(1, "days").set({
        hour: 3,
        minute: 59,
        second: 59
      }),
      branchId: branchId,
      isMonth: true
    });
    fetch(`${endpoint}/admin/singlerestreport`, {
      method: "post",
      body: postData,
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ reportspdf: result.data });
      });
  };
  sendMonthReports() {
    var branchId = this.props.match.params.id;
    var postData = {
      branchId: branchId,
      reportDate: this.state.monthlyReportDate
    };
  }

  downloadPdf = (path) => {
    // alert(path);
    // var path = path.replace(/\//, "&").split("&")[1];
    var dtt = path.replace(/\//, "&").split("&")[1];
    var srr = dtt.split("/")[1];
    if (srr == "restReports") {
      path = dtt;
    } else {
      path = path;
    }
    console.log("PATH FINAL", path);
    // alert(path);
    var postData = {
      path: path
    };
    var hdr = localStorage.token;
    fetch(`${endpoint}/admin/getpresignedurla`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "failure") {
          //console.log(result.data);
          this.openNotification("error", result.data);
        } else {
          // this.openNotification("success", "Markert som betalt");
          // let date = moment().date()._d;
          // this.getRestReports(date);
          window.open(result, "_blank");
          // this.setState({ reportspdf: result.data });
          // console.log("you got data REST REST REPORT", result);
        }
      });
  };

  render() {
    return (
      <div style={{ boxShadow: "0px 0px 4px 2px #dbdbdb", borderRadius: 10 }}>
        <Row>
        <PageHeader
          className="site-page-header"
          onBack={() => this.props.history.push(`/`)}
          title="Reports"
          subTitle={moment(this.state.selectedDate).format("l").toString()}
          >
            <div style={{display: "flex", justifyContent: "space-between"}}>
        <MonthPicker
          placeholder="Select Month"
          onChange={(date, dateString) => this.chkdt(date)}
        ></MonthPicker>
        {"  "}
          <DatePicker
            onChange={(date, dateString) => {
              this.getRestReports(date);
            }}
            format="YYYY-MM-DD"
            placeholder="Select day"
          />
              <Button style={{marginLeft:10}} onClick={() => this.sendMonthReports()}>
                Send Report
              </Button>
            </div>
          </PageHeader>
        </Row>
        <Row style={{ padding: 25 }}>
          {this.state.reportspdf &&
            this.state.reportspdf.length > 0 &&
            this.state.reportspdf.map((rest) => {
              //   if (rest.status == "PAID")
              return (
                <Col>
                  <div className="report-box">
                    <a
                      onClick={() => this.downloadPdf(rest.pdf)}
                      id={rest.pdf}
                      // download
                      // href={endpoint + rest.pdf}
                      // target="_blank"
                    >
                      <img src={unpaidimg}></img>
                    </a>
                  <p style={{ textAlign: "center", margin: 0, padding: 0 }}>
                      {rest.fileName},{moment(rest._id).format("DD MMM, YYYY")}
                  </p>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    );
  }
}
