import * as axios from "axios";
import React, { Component, useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Table, Divider, Tag } from "antd";
const { Column, ColumnGroup } = Table;

const CouponDetails = () => {
  const [data, setData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  let coupon_id = window.location.search.split("coupon_id=")[1];

  useEffect(() => {
    (async function aa() {
      const token = localStorage.getItem("token");
      const res = await axios.post(
        `${endpoint}/api/v1/admin/coupons/coupon-detail`,
        { couponId: coupon_id },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      setData([res.data.data]);
      const res2 = await axios.post(
        `${endpoint}/api/v1/admin/coupons/coupon-branch`,
        { branchIds: res.data?.data?.assignedTo },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      setBranchData(res2?.data?.data)
    })();
  }, []);

  return (
    <>
      <Table dataSource={data} pagination={false}>
        <Column title="Coupon Code" dataIndex="coupanCode" key="age" />
        <Column title="Coupan Amount" dataIndex="coupanValue" key="coupanValue" />
        <Column title="Reamainig Amount" dataIndex="reamainigValue" key="reamainigValue" />
        <Column title="Coupon Type" dataIndex="coupanType" key="coupanType" />
        <Column
          title="Restaurant"
          dataIndex="assignedTo"
          key="assignedTo"
          render={(tags) => (
            <span>
              {branchData?.map((name) => (
                <Tag color="blue" key={name}>
                  {name}
                </Tag>
              ))}
            </span>
          )}
        />
      </Table>
    </>
  );
};

export default CouponDetails;
