import React, { useState, useEffect } from "react";
import endpoint from "../../helpers/Endpoint";
import AddAdvertisement from "./AddAdvertisement";
import s3url from "../../helpers/s3";
import axios from "axios";
import NotWorkingLogo from "../../assets/1.png";
import WorkingLogo from "../../assets/2.png";
import {
  Radio,
  Select,
  Switch,
  notification,
  Icon,
  DatePicker,
  Popover,
  Button,
  Tag,
  Badge,
  Col,
  Row
} from "antd";
import "./Advertisement.css";
import CustomPopupConfirm from "./CustomPopupConfirm";
import moment from "moment";
import EditAdvertisement from "./EditAdvertisement";
import Backgrounds from "./Backgrounds";

const text = <span>Title</span>;
const content = (
  <div>
    <p>Content</p>
    <p>Content</p>
  </div>
);
const buttonWidth = 70;

const Advertisements = (props) => {
  const [Advertisement, setAdvertisement] = useState([]);
  //const [branch, setBranch] = useState({});
  const [page, setPage] = useState("list");
  const [addId, setAddId] = useState();
  const [isPopup, setPopup] = useState(false);
  const [adddId, setAdddId] = useState("");
  useEffect(() => {
    getAdvertisementList();
  }, []);

  const getAdvertisementList = async () => {
    try {
      let result = await fetch(
        `${endpoint}/api/v1/admin/branch/listAdvertisement`,
        {
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      )
        .then((result) => result.json())
        .then((result) => {
          if (result.status == 500) {
            console.log("THERE IS A FAULURE ");
          }
          if (result) {
            //console.log("data === ", result)

            setAdvertisement(result.data);
          }
        });
    } catch (error) {
      console.log("something went wrong");
    }
  };

  const addForm = () => {
    setAdvertisement({});
    setPage("add");
  };
  const backgroundForm= () => {
    setAdvertisement({});
    setPage("background");
  };
  const editForm = (item) => {
    setPage("edit");
    setAdvertisement(item);
  };

  const indexPage = () => {
    //setAdvertisement({});
    setPage("list");
    getAdvertisementList();
    //getSuperCats();
  };

  const deleteApi = async () => {
    console.log("id == ", addId);

    try {
      const res = await axios.post(
        `${endpoint}/api/v1/admin/branch/deleteAdvertisement`,
        { _id: addId }
      );

      if (res.data.status == "success") {
        setPopup(false);
        notification.open({
          message: "Your advertisement deleted successfully!",
          icon: <Icon type="check-circle" style={{ color: "green" }} />,
          duration: 2,
        });
        getAdvertisementList();
      }
    } catch (error) {
      console.log("something went wrong");
      setPopup(false);
    }
  };

  const showConformationDialog = (id) => {
    setAddId(id);
    setPopup(true);
    //deleteAddvert(id);
    //setPopup(true);
    //deleteApi(id)
  };
  const cancelConformationDialog = () => {
    setPopup(false);
  };

  const backbtn = () => {
    props.history.goBack();
    getAdvertisementList();
  };

  //   function myFunc(url) {
  //     window.location.replace(`https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com/${url}`);
  // }
  const addPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon">
                    <i
                      className="fas fa-arrow-left"
                      onClick={() => indexPage()}
                    ></i>
                  </span>
                  <span>Add Advertisement</span>
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                  <AddAdvertisement indexPage={indexPage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const editPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => indexPage()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <span>Edit Advertisement</span>
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                <EditAdvertisement
                    Advertisement={Advertisement}
                    indexPage={indexPage}
                   // branch={branch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const backgroundPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">
                <span className="icon-text">
                  <span className="icon" onClick={() => indexPage()}>
                    <i className="fas fa-arrow-left"></i>
                  </span>
                  <span>Background for Advertisement</span>
                </span>
              </h3>
              <div className="row">
                <div className="col-12">
                <Backgrounds
                    //Advertisement={Advertisement}
                    indexPage={indexPage}
                   // branch={branch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const listPage = () => {
    return (
      <>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <Row gutter={8}>
               <Col span={14} >
               <h3 className="card-title">
                  <span className="icon-text">
                    <span className="icon" onClick={() => backbtn()}>
                      <i className="fas fa-arrow-left"></i>
                    </span>
                  </span>
                  Advertisement
                </h3>
               </Col>
              <Col span={5} className="mr-2">
               <button className="button is-success" style={{marginRight:"20px"}} onClick={() => backgroundForm()}>
                  <i className="fa fa-plus"></i>&nbsp;{"Advertisement Backgrounds"}
                </button>
              </Col>
              <Col span={4} className="mr-2">
              <button className="button is-success" onClick={() => addForm()}>
                  <i className="fa fa-plus"></i>&nbsp;{"Advertisement"}
                </button>
              </Col>
              </Row>
              <div className="row">
                <div className="column">
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Branch Type</th>
                        <th>Content Type</th>
                        {/* {
                          Advertisement.map((e)=>{
                            return(
                              e.timePeriod.startDate == null ?
                              "": <td>Time Period</td>

                            )
                          })
                        } */}
                        <th>Current Status</th>
                        <th>Running Status</th>
                        <th>Advertisement Time Period</th>
                        <th>Offer</th>
                        <th>Advertisement</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Advertisement.length > 0 ? (
                        Advertisement.map((item, idx) => {
                          let today = new Date();
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>{item.branchType == "all" ? "All Branches": item.branchName}</td>
                              <td>
                                {item.branchType == "rest" ? (
                                  <p>Restaurant</p>
                                ) : item.branchType == "vendor" ? (
                                  <p>Vendor</p>
                                ) : 
                                  item.branchType == "retailer" ?
                                  (
                                  <p>Retailer</p>
                                ) : (
                                  <p>For All</p>
                                )}
                              </td>
                              <td>
                                {item.contentType == "picture" ? (
                                  <p>Image</p>
                                ) : item.contentType == "video" ? (
                                  <p>Video</p>
                                ) :
                                item.contentType == "gif" ? (
                                  <p>GIF</p>
                                ) : 
                                item.contentType == "html" ? (
                                  <p>Html</p>
                                ) : (
                                  <p>GIF</p>
                                )}
                              </td>
                              <td>
                                {
                                
item.isAdvertisementOn == "true" || today >= new Date(item.timePeriod.startDate) && today <= new Date(item.timePeriod.endDate) ? (
                                  <a  href="/">
                                    <img src={WorkingLogo}  width="120px"/>
                                  </a>
                                ) : (
                                  <a  href="/">
                                    <img src={NotWorkingLogo}  width="120px"/>
                                  </a>
                                )}
                              </td>
                              <td>
                                {item.isAdvertisementOn == "true" ? (
                                  <Tag className="tag">
                                    <p>Active</p>
                                  </Tag>
                                ) : (
                                  <Tag className="tag2">
                                    <p>One Time</p>
                                  </Tag>
                                )}
                              </td>
                              {item.timePeriod.startDate == null ? (
                                <td>No data</td>
                              ) : (
                                <td>
                                  {moment(item.timePeriod.startDate).format(
                                    "DD-MM-YYYY"
                                  )}{" "}
                                  to{" "}
                                  {moment(item.timePeriod.endDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                              )}
                              <td>
                                {
                                  item.contentType == "html" ? 
                                  
                                    <>
                                    <p>Original Price : {item.prices.originalPrice}</p>
                                    <p>Offer Price : {item.prices.offerPrice}</p>
                                    </>
                                    
                                  : "-"
                                }
                              </td>
                              <td>
                                {item.contentType == "picture" ? (
                                  <div
                                    style={{
                                      width: buttonWidth,
                                      float: "left",
                                    }}
                                  >
                                    <Popover
                                      placement="leftBottom"
                                      //title={<h3>Advertisement</h3>}
                                      content={
                                        <img
                                          src={`${s3url}/${item.url}`}
                                          className="image-advertisement"
                                          style={{
                                            width: "300px",
                                          }}
                                        />
                                      }
                                      trigger="click"
                                      overlayStyle={{
                                        width: "20vw",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={`${s3url}/${item.url}`}
                                        className="image-advertisement"
                                        style={{
                                          width: "50px",
                                        }}
                                      />
                                    </Popover>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {item.contentType == "video" ? (
                                  <div
                                    style={{
                                      width: buttonWidth,
                                      float: "left",
                                    }}
                                  >
                                    <Popover
                                      placement="leftBottom"
                                      //title={<h3>Advertisement</h3>}
                                      content={
                                        <video
                                          width="300px"
                                          height="300px"
                                          controls
                                        >
                                          <source
                                            src={`${s3url}/${item.url}`}
                                            type="video/mp4"
                                          />
                                        </video>
                                      }
                                      trigger="click"
                                      overlayStyle={{
                                        width: "20vw",
                                      }}
                                    >
                                      {" "}
                                      <video
                                        width="50px"
                                        height="50px"
                                        controls
                                      >
                                        <source
                                          src={`${s3url}/${item.url}`}
                                          type="video/mp4"
                                        />
                                      </video>
                                    </Popover>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {item.contentType == "gif" ? (
                                  <div
                                    style={{
                                      width: buttonWidth,
                                      float: "left",
                                    }}
                                  >
                                    <Popover
                                      placement="leftBottom"
                                      //title={<h3>Advertisement</h3>}
                                      content={
                                        <img
                                          src={`${s3url}/${item.url}`}
                                          className="image-advertisement"
                                          style={{
                                            width: "300px",
                                          }}
                                        />
                                      }
                                      trigger="click"
                                      overlayStyle={{
                                        width: "20vw",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={`${s3url}/${item.url}`}
                                        className="image-advertisement"
                                        style={{
                                          width: "50px",
                                        }}
                                      />
                                    </Popover>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {item.contentType == "html" ? (
                                  <div
                                    style={{
                                      width: buttonWidth,
                                      float: "left",
                                    }}
                                  >
                                    <Popover
                                      placement="leftBottom"
                                      //title={<h3>Advertisement</h3>}
                                      content={
                                        <img
                                          src={`${s3url}/${item.url}`}
                                          className="image-advertisement"
                                          style={{
                                            width: "300px",
                                          }}
                                        />
                                      }
                                      trigger="click"
                                      overlayStyle={{
                                        width: "20vw",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={`${s3url}/${item.url}`}
                                        className="image-advertisement"
                                        style={{
                                          width: "50px",
                                        }}
                                      />
                                    </Popover>
                                  </div>
                                ) : (
                                  ""
                                )}
                                
                              </td>
                              <td>
                                <button
                                  className="button is-primary "
                                  onClick={() => editForm(item)}
                                >
                                  <i className="fa fa-edit" />
                                </button>
                                <button
                                  className="button is-danger"
                                  onClick={() =>
                                    showConformationDialog(item._id)
                                  }
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" align="center">
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {page === "list" ? listPage() : page === "edit" ? editPage() : page == "background" ? backgroundPage() :addPage()}
      <CustomPopupConfirm
        visible={isPopup}
        cancel={cancelConformationDialog}
        productId={addId}
        action={deleteApi}
      />
    </>
  );
};

export default Advertisements;
