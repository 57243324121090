import React from "react";

import Notification from "react-bulma-notification";
import endpoint from "../../../helpers/Endpoint";

class UpdateAdminIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      upload: null,
      ...this.props.match.params
    };

    this.handleInputChange = this.handleInputChange.bind();
    this.handleImageChange = this.handleImageChange.bind();
    this.handleSubmit = this.handleSubmit.bind();
  }

  componentDidMount() {
    this.fetchIconDetails(this.state);
  }

  fetchIconDetails = async (state) => {
    try {
      console.log("statestatestatestate = ", state);

      let result = await fetch(`${endpoint}/api/v1/admin/adminIcons/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token")
        },
        body: JSON.stringify({
          id: state.id
        })
      }).then((result) => result.json());

      if (result.success === -1) {
        // Not Logged In
        this.openNotification("error", "Please login again to continue");
        this.props.history.push("/");
        return;
      } // Not Logged In

      if (result.success === 0) {
        this.openNotification("error", result.message);
        return;
      } else {
        console.log("result GetDetails", result);

        this.setState({
          loading: false,
          name: result.data.adminIcon.name,
          upload: null
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
        data: [],
        pages: 0
      });
      this.openNotification("error", error.message);
    }
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleImageChange = (e) => {
    e.preventDefault();

    if (e.target.files.length) {
      this.setState({
        [e.target.name]: e.target.files[0]
      });
    } else {
      this.setState({
        [e.target.name]: null
      });
    }
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();

      let { id, name, upload } = this.state;

      let formData = new FormData();

      formData.set("id", id);
      formData.set("name", name);
      if (upload) formData.append("upload", upload);

      let result = await fetch(`${endpoint}/api/v1/admin/adminIcons/update`, {
        method: "POST",
        body: formData,
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      }).then((result) => result.json());

      if (result.success === -1) {
        // Not Logged In
        this.openNotification("error", "Please login again to continue");
        this.props.history.push("/");
        return;
      } // Not Logged In

      console.log("resultresultresultresult = ", result, result.message);

      if (result.success === 0) {
        this.openNotification("error", result.message);
        return;
      }

      this.openNotification("success", result.message);

      this.setState({
        loading: false
      });

      console.log(this.state, formData);
    } catch (error) {
      this.setState({
        loading: false,
        data: [],
        pages: 0
      });
      this.openNotification("error", error.message);
    }
  };

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  render() {
    return (
      <div className="tile is-parent">
        <article className="tile is-child notification ">
          <form
            onSubmit={this.handleSubmit}
            className="form-horizontal"
            encType="multipart/form-data"
          >
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Name</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-left">
                    <input
                      required
                      className="input"
                      type="text"
                      name="name"
                      placeholder="Icon Name"
                      value={this.state.name}
                      onChange={this.handleInputChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-user" />
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Icon</label>
              </div>
              <div className="field-body">
                <div className="file">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      name="upload"
                      id="upload"
                      accept="image/*"
                      onChange={this.handleImageChange}
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload" />
                      </span>
                      <span className="file-label">Choose a file…</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <center>
              <button type="submit" className="button is-primary">
                Update icon
              </button>
            </center>
          </form>
        </article>
      </div>
    );
  }
}

export default UpdateAdminIcons;
