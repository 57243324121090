import React, { Component } from "react";
import Notification from "react-bulma-notification";
import "../../../node_modules/react-bulma-notification/build/css/index.css";
import endpoint from "../../helpers/Endpoint";
import s3url from "../../helpers/s3";

class Backgrounds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      icons: []
    };
  }

  componentDidMount = () => {
    this.showIcons();
  };

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  showIcons = () => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/api/v1/admin/branch/getAdBackgrounds`, {
      method: "GET",
      headers: {
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
        } else {
          this.setState({ icons: result.data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };

  handleChange = (e) => {
    const file = e.target.files[0];
    if(file ==undefined){
        this.openNotification("success", "Select Proper Image");
    }else{
        console.log("file",file)
        const formData = new FormData();
        formData.append("image", file);
        const token = localStorage.getItem("token");
        function handleErrors(result) {
          if (!result.ok) {
            throw Error(result.status);
          }
          return result;
        }
        fetch(`${endpoint}/api/v1/admin/branch/addAdBackgrounds`, {
          method: "POST",
          body: formData,
          headers: {
            "x-auth-token": token
          }
        })
          .then(handleErrors)
          .then((result) => result.json())
          .then((result) => {
            if (result.status === "failure") {
              console.log(result);
              this.openNotification("error", result.data);
            } else {
              this.showIcons();
              this.openNotification("success", result.message);
            }
          })
          .catch((error) => console.log(error));
    }
  };

  deleteIcon = (id) => {
    console.log("deleteBackgrounds id === ",id)
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    // fetch(`${endpoint}/api/v1/admin/branch/deleteAdBackgrounds`, {
    //   method: "post",
    // //   body: data,
    //   headers: {
    //     "x-auth-token": token
    //   },
    //   body: JSON.stringify({
    //     _id: id
    //   }),
    // })
    //   .then(handleErrors)
    //   .then((result) => result.json())
    //   .then((result) => {
    //     if (result.status === "failure") {
    //       console.log(result);
    //       this.openNotification("success", result.data);
    //     } else {
    //       this.showIcons();
    //       this.openNotification("success", result.message);
    //     }
    //   })
    //   .catch((error) => console.log(error));

    var postData = JSON.stringify({
    _id: id
    });
    fetch(`${endpoint}/api/v1/admin/branch/deleteAdBackgrounds`, {
      method: "post",
      body: postData,
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    })
      // .then((result) => result.json())
      // // .then((result) => {
      // //   console.log("result", result)
      // //   // this.setState({ reportspdf: result.data });
      // // })
      .then((result) => {
            if (result.status === "failure") {
              console.log(result);
              this.openNotification("success", result.status ? "Advertisement not deleted":"");
            } else {
              this.showIcons();
              this.openNotification("success", result.status ?"Background deleted successfully":"" );
            }
          })
          .catch((error) => console.log(error));
  };

  render() {
    const { icons, isLoaded } = this.state;
    return (
      <div
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          borderRadius:10
        }}
      >
        <div className="tile is-parent">
          <article className="tile is-child notification ">
            <div className="content">
              <div className="file">
                <label className="file-label">
                  <input
                    className="file-input"
                    type="file"
                    name="icon"
                    onChange={this.handleChange}
                  />
                  {/* <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                    <span className="file-label">Choose a file…</span>
                  </span> */}
                </label>
              </div>
            </div>
          </article>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent:"space-between",
          }}
        >
          {isLoaded
            ? icons.map((icon, index) => {
                return (
                  <div className="image-icon-box" key={icon._id}>
                    <img
                      src={`${s3url}/${icon.url}`}
                      className="image-icon"
                      alt="icon"
                    />
                    <a
                      className="delete is-medium"
                      onClick={() => this.deleteIcon(icon._id)}
                    />
                  </div>
                );
              })
            : "Loading..."}
        </div>
      </div>
    );
  }
}

export default Backgrounds;
