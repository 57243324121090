import React, { isValidElement, useEffect, useState } from "react";
import { Select } from "antd";
import endpoint from "../../../helpers/Endpoint";
import Notification from "react-bulma-notification";
import { OmitProps } from "antd/lib/transfer/renderListBody";

const { Option } = Select;
const validationform = {
  isMachineipValid: false,
  isDeviceIdValid: false,
  isMacineSerialValid: false
};
const FormPage = (props) => {
  let [machineIp, setMachineIp] = useState(
    props.singleMachine ? props.singleMachine.verifoneIp : ""
  );
  let [deviceId, setdeviceId] = useState(
    props.singleMachine ? props.singleMachine.deviceId : ""
  );

  let [machineSerial, setMachineeSerial] = useState(
    props.singleMachine ? props.singleMachine.verifoneSerialNumber : ""
  );
  let isEdit = props.isEdit;
  let handleChangeDeviceId = (value) => {
    validationform.isDeviceIdValid = true;
    console.log(validationform);
    setdeviceId(value);
  };
  let devices = props.devices && props.devices.length > 0 ? props.devices : [];

  //useEffect(() => {}, []);

  let openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  const changeMachineIp = (event) => {
    if (event.target.value.trim().length > 0) {
      validationform.isMachineipValid = true;
    } else {
      validationform.isMachineipValid = false;
    }

    setMachineIp(event.target.value);
  };

  const chnageMachineeSerial = (event) => {
    if (event.target.value.trim().length > 0) {
      validationform.isMacineSerialValid = true;
    } else {
      validationform.isMacineSerialValid = false;
    }
    setMachineeSerial(event.target.value);
  };
  const saveMachineConfig = (e) => {
    e.preventDefault();
    const slug = props.slug;
    // console.log(props.slug);
    const url = props.isEdit
      ? `${endpoint}/api/v1/admin/verifone/updateverifoneMachine`
      : `${endpoint}/api/v1/admin/verifone/addverifonemachine`;
    const token = localStorage.getItem("token");
    const data = isEdit
      ? JSON.stringify({
          machineIp,
          machineSerial,
          deviceId,
          slug: slug,
          id: props.singleMachine._id
        })
      : JSON.stringify({
          machineIp,
          machineSerial,
          deviceId,
          branchId: props.branchId,
          slug: slug
        });
    fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result) {
          //  props.getAllMachine();
          if (result.status == 200) {
            validationform.isDeviceIdValid = false;
            validationform.isMachineipValid = false;
            validationform.isMacineSerialValid = false;
            props.pageChangeHandler(true, false, null);
          } else {
            openNotification("error", result.message);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        className="row"
        style={{ width: "50%", margin: "auto", height: "70%" }}
      >
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">
              <span class="icon-text">
                <span
                  class="icon"
                  onClick={() => {
                    props.pageChangeHandler(true, false, null);
                  }}
                >
                  <i class="fas fa-arrow-left"></i>
                </span>
                <span>Update Configuration</span>
              </span>
            </h3>
            <div className="row " style={{ padding: "5%" }}>
              <div className="col-12">
                <form
                  onSubmit={saveMachineConfig}
                  className="form-horizontal box"
                >
                  <div className="field">
                    <label className="label">Device </label>
                    <div className="control">
                      <div style={{ width: "100%" }}>
                        <Select
                          style={{ width: "100%" }}
                          className="input"
                          defaultValue={deviceId}
                          onChange={handleChangeDeviceId}
                        >
                          {devices.length > 0
                            ? devices.map((sinDev, index) => {
                                return (
                                  <Option
                                    key={sinDev._id}
                                    value={sinDev.deviceId.deviceId}
                                  >
                                    {sinDev.deviceId.deviceId}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Machine IP</label>
                    <div className="control">
                      <div className="field">
                        <input
                          className="input"
                          type="text"
                          name="macineip"
                          placeholder="Enter Machine Ip"
                          value={machineIp}
                          onChange={changeMachineIp}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Machine Serial No </label>
                    <div className="control">
                      <div className="field">
                        <input
                          className="input"
                          type="text"
                          name="serialno"
                          placeholder="Enter  Serial No"
                          value={machineSerial}
                          onChange={chnageMachineeSerial}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    style={{ width: "100%" }}
                    class="button is-primary"
                    disabled={
                      props.isEdit
                        ? false
                        : !validationform.isDeviceIdValid ||
                          !validationform.isMachineipValid ||
                          !validationform.isMacineSerialValid
                    }
                  >
                    {"Save"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormPage;
