import React, { useEffect, useState } from "react";
import { Table, Divider, Tag } from "antd";
import endpoint from "../../helpers/Endpoint";

const columns = [
  {
    title: "User Name",
    dataIndex: "email",
    key: "email",
    render: (text) => <a>{text !== "" ? text : "--"}</a>
  },
  {
    title: "Branch Name",
    dataIndex: "branch_name",
    key: "branch_name",
    render: (text) => <a>{text !== "" ? text : "--"}</a>
  },
  {
    title: "Login Type",
    dataIndex: "login_type",
    key: "login_type",
    // render: (text, da) => <a>{text !== "" ? da.machineType == "6 Inchi" && text == "device" ? `${text} 📱` : `${text == "device" && da.machineType == "15 inch" ? `${text} 🖥️` : text}` : "--"}</a>
    render: (text, da) => <a>{text === "web" ? "Web" : da.machineType ? da.machineType : "--"}</a>
  },
  {
    title: "Login Time",
    dataIndex: "login_time",
    key: "login_time",
    render: (text) => <a>{text !== null ? text?.split("T")[0].replaceAll("-","/")+" "+text?.split("T")[1].split(".")[0] : "--"}</a>,
  },
  {
    title: "Logout Time",
    dataIndex: "logout_time",
    key: "logout_time",
    render: (text) => <a>{text !== null ? text?.split("T")[0].replaceAll("-","/")+" "+text?.split("T")[1].split(".")[0] : "--"}</a>,
  },
];

const LoginDetails = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${endpoint}/api/v1/admin/employeeLogin`)
  .then((response) => response.json())
  .then((d) => setData(d.data));
  }, []);

  const datad = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["nice", "developer"],
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["loser"],
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sidney No. 1 Lake Park",
      tags: ["cool", "teacher"],
    },
  ];
  return (
    <div style={{ boxShadow: "0px 0px 4px 2px #dbdbdb",borderRadius:10 }}>
      <Table className="loginActivity" columns={columns} bordered dataSource={data} />
    </div>
  );
};

export default LoginDetails;
