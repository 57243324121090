import React, { Component, Fragment } from "react";
import {
  PageHeader,
  DatePicker,
  Table,
  Input,
  Modal,
  Row,
  Col,
  Button,
  Card
} from "antd";
import moment from "moment";
import Notification from "react-bulma-notification";
import endpoint from "../../helpers/Endpoint";
import CurrencyFormat from "react-currency-format";

export default class Oppgjør extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restData: [],
      restOnlineData: [],
      restOnlineVippsData: [],

      cashTax: 0,
      bankTax: 0,
      fakturaTax: 0,
      webTax: 0,
      restaurants: [],
      modalVisible: false,
      editingRestId: "",
      editingRestName: "",
      editingtaccountantEmail: "",
      currentTotalTax: 0,
      restOnlineQrVippsData: 0,
      currentCashTax: 0,
      currentBankTax: 0,
      currentWebTax: 0,
      currentFakturaTax: 0,
      renderTotalslgState: "",
      totalTax: 0,
      tdTax: 0,
      totalNetto: 0,
      flatAmt: 0,
      remainingAmt: 0,
      coupanTax: 0,
      vippsTax: 0,
      taxesArr: [
        // {
        //   _id: "5e233da0ca00951c3cd17251",
        //   totalTax: 15,
        //   cashTax: 14,
        //   bankTax: 10,
        //   webTax: 9,
        //   fakturaTax: 13
        // },
        // {
        //   _id: "5e233da0ca00951c3cd17281",
        //   totalTax: 5,
        //   cashTax: 12,
        //   bankTax: 21,
        //   webTax: 11,
        //   fakturaTax: 12
        // }
      ],
      totalubetaltFaktura: [],
      currentScreen: 1,
      singleRestOppgjor: {},
      singleOppgjor: {},
      singleOppgjorDate: moment().date()._d,
      singleOppgjorTaxes: {},
      currentOnlineTotal: 0,
      currentNetto: 0,
      currentNettoTax: 0,
      currentfinalTotal: 0,
      lnding: false,
      remainingAmount: [],
      restqronlinereportData: [],
      restTotal: []
    };
  }
  componentDidMount() {
    var date = moment().date()._d;
    this.getrestReport(date);
    this.getrestqronlinereport(date);
    this.getRestaurants();
    this.getTaxes(new Date());
    this.getrestOnlineReport(date);
    this.restReportUbetaltFakura(date);
    this.getRemainingAmtApi(date);
    this.getrestVippsOnlineReport(date);
    this.getrestQRVippsOnlineReport(date);
    this.getTdlReport(date);

    this.getrestTotal(date);
  }
  handleOk = (e) => {
    this.setState({
      modalVisible: false
    });
  };
  getCurrentTaxes = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);

    if (!found.length !== 0) {
      this.setState({
        totalTax: found.length ? found[0].totalTax : 0,
        cashTax: found.length ? found[0].cashTax : 0,
        bankTax: found.length ? found[0].bankTax : 0,
        fakturaTax: found.length ? found[0].fakturaTax : 0,
        webTax: found.length ? found[0].webTax : 0,
        flatAmt: found.length ? found[0].flatAmt : 0,
        coupanTax: found.length ? found[0].coupanTax : 0,
        vippsTax: found.length ? found[0].vippsTax : 0,
        tdTax: found.length ? found[0].treeDriveTax : 0
      });
    }
  };

  handleCancel = (e) => {
    this.setState({
      modalVisible: false
    });
  };

  getRestaurants = () => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/opgjrRestaurant`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          const data = result.data.filter((item) => !item.isDeleted);
          this.setState({ restaurants: data, isLoaded: true });
        }
      })
      .catch((error) => console.log(error));
  };

//backup old Api /restaurant with no isNewreports check at BE
  // getRestaurants = () => {
  //   const token = localStorage.getItem("token");
  //   function handleErrors(result) {
  //     if (!result.ok) {
  //       throw Error(result.status);
  //     }
  //     return result;
  //   }
  //   fetch(`${endpoint}/admin/restaurant`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-auth-token": token
  //     }
  //   })
  //     .then(handleErrors)
  //     .then((result) => result.json())
  //     .then((result) => {
  //       if (result.status === "failure") {
  //       } else {
  //         const data = result.data.filter((item) => !item.isDeleted);
  //         this.setState({ restaurants: data, isLoaded: true });
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // };
  onTaxChange = () => {};

  openaddTaxModal = (id, name, email) => {
    this.setState({
      editingRestId: id,
      editingRestName: name,
      editingtaccountantEmail: email,
      modalVisible: true
    });
  };

  getrestOnlineReport = (date) => {
    // this.setState({ restOnlineData: [] });
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    // var date = moment().date()._d;
    this.setState({ restOnlineData: [] });
    // let data = JSON.stringify({
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    fetch(`${endpoint}/admin/restonlinereport`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          if (result.data) {
            this.setState({ restOnlineData: result.data });
          } else {
            this.setState({ restOnlineData: [] });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  getrestVippsOnlineReport = (date) => {
    // this.setState({ restOnlineData: [] });
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    // var date = moment().date()._d;
    this.setState({ restOnlineVippsData: [] });
    // let data = JSON.stringify({
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    fetch(`${endpoint}/admin/onlinevippsreport`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          if (result.data) {
            this.setState({ restOnlineVippsData: result.data });
          } else {
            this.setState({ restOnlineVippsData: [] });
          }
        }
      })
      .catch((error) => console.log(error));
  };
  //alka
  getrestqronlinereport = (date) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    // var date = moment().date()._d;
    //this.setState({ restqronlinereportData: [] });
    // let data = JSON.stringify({
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    fetch(`${endpoint}/admin/restqronlinereport`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          if (result.data) {
            this.setState({ restqronlinereportData: result.data });
          } else {
            this.setState({ restqronlinereportData: [] });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  getrestQRVippsOnlineReport = (date) => {
    // this.setState({ restOnlineData: [] });
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    // var date = moment().date()._d;
    this.setState({ restOnlineQrVippsData: [] });
    // let data = JSON.stringify({
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    fetch(`${endpoint}/admin/qrvippsreport`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          if (result.data) {
            this.setState({ restOnlineQrVippsData: result.data });
          } else {
            this.setState({ restOnlineQrVippsData: [] });
          }
        }
      })
      .catch((error) => console.log(error));
  };
  //alka
  getrestTotal = (date) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    this.setState({ restTotal: [] });
    // let data = JSON.stringify({
    //   startDate: moment(date)
    //     // .subtract(1,"days")
    //     .set({
    //       hour: 4,
    //       minute: 0,
    //       second: 0
    //     }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    fetch(`${endpoint}/admin/restTotal`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          if (result.data) {
            this.setState({ restTotal: result.data });
          } else {
            this.setState({ restTotal: [] });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  getTdlReport = (date) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    this.setState({ tdData: [] });
    // let data = JSON.stringify({
    //   startDate: moment(date)
    //     // .subtract(1,"days")
    //     .set({
    //       hour: 4,
    //       minute: 0,
    //       second: 0
    //     }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    fetch(`${endpoint}/api/v1/admin/tdlreport/gettdlreport`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          if (result.data) {
            //console.log(result.data, "resdata");
            this.setState({ tdData: result.data });
          } else {
            this.setState({ tdData: [] });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  getrestReport = (date) => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    this.setState({ restData: [] });
    // let data = JSON.stringify({
    //   startDate: moment(date)
    //     // .subtract(1,"days")
    //     .set({
    //       hour: 4,
    //       minute: 0,
    //       second: 0
    //     }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    fetch(`${endpoint}/admin/restreport`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          if (result.data) {
            this.setState({ restData: result.data });
          } else {
            this.setState({ restData: [] });
          }
        }
      })
      .catch((error) => console.log(error));
  };
  getRemainingAmtApi = (date) => {
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    // var postData = {
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // };

    let postData = {
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
      }
    fetch(`${endpoint}/api/v1/admin/settlement/getRemainingAmt`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          remainingAmount: result.data && result.data.length ? result.data : []
        });
      });
  };
  restReportUbetaltFakura = (date) => {
    this.setState({ isLoaded: false });
    var hdr = localStorage.token;
    let dt = moment().date()._d;
    // var postData = {
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   }),
    //   endDate: moment(date).add(1, "days").set({
    //     hour: 3,
    //     minute: 59,
    //     second: 59
    //   })
    // };
    var postData = {
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    };
    this.setState({
      totalubetaltFaktura: []
    });
    fetch(`${endpoint}/admin/restreportubetaltfakura`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "x-auth-token": hdr,
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.data) {
          this.setState({
            totalubetaltFaktura: result.data
          });
        }
      });
  };

  getTaxes = (date) => {
    let token = localStorage.getItem("token");
    // let data = JSON.stringify({
    //   startDate: moment(date).set({
    //     hour: 4,
    //     minute: 0,
    //     second: 0
    //   })
    // });
    let data = JSON.stringify({
        startDate: moment(date),
        endDate: moment(date).add(1, "days")
    });

    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/getadmintaxes`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          this.setState({ taxesArr: result.data });
        }
      })
      .catch((error) => console.log(error));
  };
  // total tax and total amount
  renderTotalslg = (id) => {
    var found = this.state.restData.filter((el) => el._id === id);
    if (found && found.length == 0) {
      // this.setState({ renderTotalslgState: `<p>--</p>` });
      return <p>--</p>;
    } else {
      return (
        <CurrencyFormat
          disabled={true}
          style={{ borderWidth: 0, textAlign: "center", width: 70 }}
          value={found[0].total.toFixed(2)}
          displayType={"number"}
          thousandSeparator={true}
          // prefix={"Kr."}
        />
      );
    }
  };
  renderTotalTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].totalTax.toString()}</p>;
    }
  };

  // ============== render total tax and total amount end

  //  render total cash amount  and total cash amount start

  renderTotalCash = (id) => {
    var found = this.state.restData.filter((el) => el._id === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return (
        <CurrencyFormat
          disabled={true}
          style={{ borderWidth: 0, textAlign: "center", width: 70 }}
          value={found[0].totalCash}
          displayType={"number"}
          thousandSeparator={true}
          // prefix={"Kr."}
        />
      );
    }
  };
  renderTotalCoupan = (id) => {
    var found = this.state.restData.filter((el) => el._id === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return (
        <CurrencyFormat
          disabled={true}
          style={{ borderWidth: 0, textAlign: "center", width: 70 }}
          value={found[0].totalCoupon.toFixed(2)}
          displayType={"number"}
          thousandSeparator={true}
          // prefix={"Kr."}
        />
      );
    }
  };

  renderTotalViips = (id) => {
    var found = this.state.restData.filter((el) => el._id === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return (
        <CurrencyFormat
          disabled={true}
          style={{ borderWidth: 0, textAlign: "center", width: 70 }}
          value={found[0].totalCoupon.toFixed(2)}
          displayType={"number"}
          thousandSeparator={true}
          // prefix={"Kr."}
        />
      );
    }
  };

  renderTotalCashTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].cashTax}</p>;
    }
  };

  renderTotalCopanTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].coupanTax}</p>;
    }
  };

  renderTotalVippsTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].vippsTax}</p>;
    }
  };

  //  render total cash amount and tax end

  //  render total bank amount and tax start

  renderTotalBank = (id) => {
    var found = this.state.restData.filter((el) => el._id === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return (
        <CurrencyFormat
          disabled={true}
          style={{ borderWidth: 0, textAlign: "center", width: 70 }}
          value={found[0].totalBank.toFixed(2)}
          displayType={"number"}
          thousandSeparator={true}
          // prefix={"Kr."}
        />
      );
    }
  };

  renderTdlOrders = (id) => {
    var found = this.state.tdData.filter((el) => el._id === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return (
        <CurrencyFormat
          disabled={true}
          style={{ borderWidth: 0, textAlign: "center", width: 70 }}
          value={found[0].taxPriceOriginal.toFixed(2)}
          displayType={"number"}
          thousandSeparator={true}
          // prefix={"Kr."}
        />
      );
    }
  };

  renderTotalBankTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].bankTax}</p>;
    }
  };

  //  render total bank amount and tax end

  //  render Total web amount and tax start
  renderTotalWeb = (id) => {
    var found = this.state.restOnlineData.filter((el) => el._id === id);
    let found1 = this.state.restqronlinereportData.filter((el) => el._id == id);
    let Qr = 0;
    let Online = 0;
    if (found.length == 0 && found1.length == 0) {
      return <p>--</p>;
    } else if (found.length == 0 && found1.length > 0) {
      Online = 0;
      Qr = found1[0].onlineTotal.toFixed(2);
    } else if (found1.length == 0 && found.length > 0) {
      Online = found[0].onlineTotal.toFixed(2);
      Qr = 0;
    } else {
      Qr = found1[0].onlineTotal.toFixed(2);

      Online = found[0].onlineTotal.toFixed(2);
    }
    return (
      <CurrencyFormat
        disabled={true}
        style={{ borderWidth: 0, textAlign: "center", width: 70 }}
        value={Number(Qr) + Number(Online)}
        displayType={"number"}
        thousandSeparator={true}
        // prefix={"Kr."}
      />
    );
  };

  renderTotalVippsWeb = (id) => {
    var found = this.state.restOnlineVippsData.filter((el) => el._id === id);
    var found1 = this.state.restOnlineQrVippsData.filter((el) => el._id === id);
    let vippsQr = 0;
    let vippsOnline = 0;
    // if (found.length == 0 && found1.length == 0) {
    //   return <p>--</p>;
    // } else if (found.length == 0 && found1.length > 0) {
    //   vippsOnline = 0;
    //   vippsQr = found1[0].onlineTotal.toFixed(2);
    // } else if (found1.length == 0 && found.length > 0) {
    //   vippsOnline = found[0].onlineTotal.toFixed(2);
    //   vippsQr = 0;
    // } else {
    //   vippsQr = found1[0].onlineTotal.toFixed(2);

    //   vippsOnline = found[0].onlineTotal.toFixed(2);
    // }

    if (found1 && found1.length > 0) {
      vippsOnline = 0;
      vippsQr = found1[0].onlineTotal.toFixed(2);
    }

    return (
      <CurrencyFormat
        disabled={true}
        style={{ borderWidth: 0, textAlign: "center", width: 70 }}
        value={Number(vippsQr)}
        displayType={"number"}
        thousandSeparator={true}
        // prefix={"Kr."}
      />
    );
  };

  totalVippsWeb = (id) => {
    var found = this.state.restOnlineVippsData.filter((el) => el._id === id);
    var found1 = this.state.restOnlineQrVippsData.filter((el) => el._id === id);
    let vippsQr = 0;
    let vippsOnline = 0;
    // if (found.length == 0 && found1.length == 0) {
    //   vippsOnline = 0;
    //   vippsQr = 0;
    // } else if (found.length == 0 && found1.length > 0) {
    //   vippsOnline = 0;
    //   vippsQr = found1[0].onlineTotal.toFixed(2);
    // } else if (found1.length == 0 && found.length > 0) {
    //   vippsOnline = found[0].onlineTotal.toFixed(2);
    //   vippsQr = 0;
    // } else {
    //   vippsQr = found1[0].onlineTotal.toFixed(2);

    //   vippsOnline = found[0].onlineTotal.toFixed(2);
    // }

    if (found1.length && found1.length > 0) {
      vippsOnline = 0;
      vippsQr = found1[0].onlineTotal.toFixed(2);
    }

    return Number(vippsQr);
  };

  totalTreeDrive = (id) => {
    var found1 = this.state.tdData.filter((el) => el._id === id);
    let td = 0;
    if (found1.length == 0) {
      td = 0;
    } else {
      td = found1[0].taxPriceOriginal.toFixed(2);
    }

    return Number(td);
  };

  totalVippsBank = (id) => {
    // var found = this.state.restOnlineVippsData.filter((el) => el._id === id);
    var found1 = this.state.restOnlineQrVippsData.filter((el) => el._id === id);
    let vippsQr = 0;
    if (found1.length == 0) {
      vippsQr = 0;
    } else {
      vippsQr = found1[0].onlineTotal.toFixed(2);
    }

    return Number(vippsQr);
  };

  renderTotalWebTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].webTax}</p>;
    }
  };

  renderTreeDriveTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].treeDriveTax}</p>;
    }
  };

  //  render total web amount and tax end

  //  render total Faktura amount and tax start

  renderTotalFaktura = (id) => {
    var found =
      this.state.totalubetaltFaktura.length > 0
        ? this.state.totalubetaltFaktura.filter((el) => el._id === id)
        : [];
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return (
        <CurrencyFormat
          disabled={true}
          style={{ borderWidth: 0, textAlign: "center", width: 70 }}
          value={found[0].totalPrice}
          displayType={"number"}
          thousandSeparator={true}
          // prefix={"Kr."}
        />
      );
    }
  };

  getTotalFaktura = (id) => {
    var found =
      this.state.totalubetaltFaktura.length > 0
        ? this.state.totalubetaltFaktura.filter((el) => el._id === id)
        : [];
    if (found.length == 0) {
      return 0;
    } else {
      return found[0].totalPrice ? found[0].totalPrice : 0;
    }
  };

  renderTotalFakturaTax = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      return <p>{found[0].fakturaTax.toFixed(2)}</p>;
    }
  };

  renderTotalFlat = (id) => {
    var found = this.state.taxesArr.filter((el) => el.branchId === id);
    if (found.length == 0) {
      return <p>--</p>;
    } else {
      let flatAmt = found[0].flatAmt ? Number(found[0].flatAmt) : 0;
      return <p>{flatAmt}</p>;
    }
  };
  rederRemaining = (id) => {
    var found = this.state.remainingAmount.length
      ? this.state.remainingAmount.filter((el) => el._id === id)
      : [];
    var foundTotal = this.state.restData.length
      ? this.state.restData.filter((el) => el._id === id)
      : [];
    if (found.length == 0 && foundTotal.length == 0) {
      return <p>--</p>;
    } else {
      const rTotal =
        found[0] && found[0].remainingAmt ? Number(found[0].remainingAmt) : 0;
      const total =
        foundTotal[0] && foundTotal[0].total ? Number(foundTotal[0].total) : 0;
      const cTotal = Number(this.rederNetto(id, "NettoTotalTaxPlus"));
      let fTotal = Number(rTotal + cTotal - total).toFixed(2);
      return rTotal > 0 ? rTotal.toFixed(2) : "--";
    }
  };
  rederFinalTotal = (id) => {
    var found = this.state.remainingAmount.length
      ? this.state.remainingAmount.filter((el) => el._id === id)
      : [];
    var foundTotal = this.state.restData.length
      ? this.state.restData.filter((el) => el._id === id)
      : [];
    // if (found.length == 0 && foundTotal.length == 0) {
    //   return <p>--</p>;
    // } else {
    const rTotal =
      found[0] && found[0].remainingAmt ? Number(found[0].remainingAmt) : 0;
    const total =
      foundTotal[0] && foundTotal[0].total ? Number(foundTotal[0].total) : 0;
    const cTotal = Number(this.rederNetto(id, "NettoTotalTaxPlus"));
    let fTotal = Number(rTotal + cTotal - total);
    return rTotal > 0
      ? Number(cTotal + rTotal).toFixed(2)
      : Number(cTotal).toFixed(2);
    // }
  };
  //  render total Faktura amount and tax end

  //  render NETTO START
  rederNetto = (id, type) => {
    var total = 0,
      cashTotal = 0,
      bankTotal = 0,
      webTotal = 0,
      fakturaTotal = 0,
      flatTotal = 0,
      coupanTotal = 0,
      treeDrive = 0,
      qrBank = 0,
      vippsTotalWithTax = 0;

    var foundTotal = this.state.restData.filter((el) => el._id === id);
    let tdlTotal = this.state.tdData.filter((el) => el._id);
    var foundTotalTax = this.state.taxesArr.filter((el) => el.branchId === id);
    var foundUbetalt =
      this.state.totalubetaltFaktura.length > 0
        ? this.state.totalubetaltFaktura.filter((el) => el._id === id)
        : [];

    let foundOnlineTotalqr = this.state.restqronlinereportData.filter(
      (el) => el._id == id
    );

    let OnlineTotalqr1;
    if (foundOnlineTotalqr.length !== 0 && foundOnlineTotalqr[0].onlineTotal) {
      OnlineTotalqr1 = foundOnlineTotalqr[0].onlineTotal;
    } else {
      OnlineTotalqr1 = 0;
    }

    var foundOnlineTotal = this.state.restOnlineData.filter(
      (el) => el._id === id
    );

    let vippsTotal = this.totalVippsWeb(id);

    if (foundTotal.length !== 0 && foundTotal[0].total) {
      if (foundTotalTax.length !== 0 && foundTotalTax[0].totalTax) {
        total =
          (Number(foundTotalTax[0].totalTax) * Number(foundTotal[0].total)) /
          Number(100);
      } else {
        // total = foundTotal[0].total;
        total = 0
      }
    }
    if (foundTotal.length !== 0 && foundTotal[0].totalCash) {
      if (foundTotalTax.length !== 0 && foundTotalTax[0].cashTax) {
        cashTotal =
          (Number(foundTotalTax[0].cashTax) * Number(foundTotal[0].totalCash)) /
          Number(100);
      } else {
        cashTotal = 0;
      }
    }
    if (tdlTotal.length !== 0 && tdlTotal[0].taxPriceOriginal) {
      console.log(foundTotalTax, "foundtax");
      if (foundTotalTax.length !== 0 && foundTotalTax[0].treeDriveTax) {
        treeDrive =
          (Number(foundTotalTax[0].treeDriveTax) *
            Number(tdlTotal[0].taxPriceOriginal)) /
          Number(100);
      } else {
        treeDrive = 0;
      }
    }

    if (vippsTotal != 0) {
      if (foundTotalTax.length !== 0 && foundTotalTax[0].vippsTax) {
        vippsTotalWithTax =
          (Number(foundTotalTax[0].vippsTax) * Number(vippsTotal)) /
          Number(100);
      } else {
        vippsTotalWithTax = 0;
      }
    }
    let vippsBank = this.totalVippsBank(id);
    if (vippsBank) {
      if (foundTotalTax.length !== 0 && foundTotalTax[0].bankTax) {
        qrBank = (Number(foundTotalTax[0].bankTax) * vippsBank) / Number(100);
      } else {
        qrBank = 0;
      }
    }
    if (foundTotal.length !== 0 && foundTotal[0].totalBank) {
      if (foundTotalTax.length !== 0 && foundTotalTax[0].bankTax) {
        bankTotal =
          (Number(foundTotalTax[0].bankTax) * Number(foundTotal[0].totalBank)) /
          Number(100);
        bankTotal = bankTotal;
      } else {
        bankTotal = 0;
      }
    }

    if (foundTotal.length !== 0 && foundTotal[0].totalCoupon) {
      if (foundTotalTax.length !== 0 && foundTotalTax[0].coupanTax) {
        coupanTotal =
          (Number(foundTotalTax[0].coupanTax) *
            Number(foundTotal[0].totalCoupon)) /
          Number(100);
      } else {
        coupanTotal = 0;
      }
    }

    if (foundUbetalt.length !== 0 && foundUbetalt[0].totalPrice) {
      if (foundTotalTax.length !== 0 && foundTotalTax[0].fakturaTax) {
        fakturaTotal =
          (Number(foundTotalTax[0].fakturaTax) *
            Number(foundUbetalt[0].totalPrice)) /
          Number(100);
      } else {
        fakturaTotal = 0;
      }
    }

    if (
      (foundOnlineTotal.length !== 0 && foundOnlineTotal[0].onlineTotal) ||
      OnlineTotalqr1
    ) {
      if (
        (foundOnlineTotal.length !== 0 || OnlineTotalqr1) &&
        foundTotalTax[0] &&
        foundTotalTax[0].webTax
      ) {
        let onlineTotal =
          foundOnlineTotal.length && foundOnlineTotal[0].onlineTotal
            ? foundOnlineTotal[0].onlineTotal
            : 0;

        webTotal =
          (Number(foundTotalTax[0].webTax) *
            Number(onlineTotal + Number(OnlineTotalqr1))) /
          Number(100);
      } else {
        webTotal = 0;
      }
    }

    // if (foundTotal.length !== 0 && foundTotal[0].total) {
    if (foundTotalTax.length !== 0 && foundTotalTax[0]) {
      flatTotal = Number(foundTotalTax[0].flatAmt);
    }
    // }
    if (foundTotalTax.length > 0 || flatTotal > 0) {
      var netto =
        Number(total) +
        Number(cashTotal) +
        Number(bankTotal) +
        Number(fakturaTotal) +
        Number(webTotal) +
        Number(coupanTotal) +
        Number(vippsTotalWithTax) +
        Number(treeDrive) +
        Number(flatTotal);
      if (type == "Netto") {
        return netto.toFixed(2);
      } else if (type == "NettoTax") {
        return (Number(0.25) * Number(netto)).toFixed(2);
      } else {
        return (Number(0.25) * Number(netto) + Number(netto)).toFixed(2);
      }
    } else {
      return 0.0;
    }
  };

  onChange(date, dateString) {}
  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  saveTaxes = () => {
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    var postData = {
      branchId: this.state.editingRestId,
      temail: this.state.editingtaccountantEmail,
      totalTax: this.state.totalTax,
      cashTax: this.state.cashTax,
      bankTax: this.state.bankTax,
      webTax: this.state.webTax,
      fakturaTax: this.state.fakturaTax,
      flatAmt: this.state.flatAmt,
      coupanTax: this.state.coupanTax,
      vippsTax: this.state.vippsTax,
      tdTax: this.state.tdTax
    };
    fetch(`${endpoint}/admin/admintaxes`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
        } else {
          this.openNotification("success", "Taxes Update for this branch");
          this.setState({ modalVisible: false, currentScreen: 1 });
          this.getTaxes();
          // this.setState({ restData: result.data });
        }
      })
      .catch((error) => console.log(error));
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  currentOppgjorData = async (rest, date) => {
    this.setState({
      lnding: true,
      currentScreen: 2,
      singleRestOppgjor: rest,
      currentOnlineTotal: 0,
      singleOppgjor: {},
      singleOppgjorTaxes: {},
      branchId: rest._id
    });
    this.restReportUbetaltFakura(date);
    this.getrestVippsOnlineReport(date);
    this.getrestQRVippsOnlineReport(date);
    this.getTdlReport(date);
    this.getCurrentTaxes(rest._id);
    const token = localStorage.getItem("token");
    var postData = {
      id: rest._id,
      startDate: moment(date),
      endDate: moment(date).add(1, "days")
    };
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/singlerestopgjor`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then(async (result) => {
        if (result.status === "failure") {
        } else {
          var taxx = await this.currentTaxes(rest._id);
          var onlineTotal = await this.currentOnlineData(rest._id);
          this.currentNettoCalculation(result.data[0], taxx, (onlineTotal = 0));
          this.setState({ singleOppgjor: result.data[0], lnding: false });
        }
      })
      .catch((error) => console.log(error));
  };
  currentTaxes = (restId) => {
    let token = localStorage.getItem("token");
    let data = JSON.stringify({
      branchId: restId,
      startDate: moment(this.state.singleOppgjorDate)
    });
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/getsingleresttaxes`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then(async (result) => {
        if (result.status === "failure") {
        } else {
          this.setState({ singleOppgjorTaxes: result.data[0] || {} });
          var txxx = result.data[0];
          return JSON.stringify(txxx);
        }
      })
      .catch((error) => console.log(error));
  };
  currentOnlineData = (restId) => {
    let token = localStorage.getItem("token");
    let data = JSON.stringify({
      id: restId,
      startDate: moment(this.state.singleOppgjorDate),
      endDate: moment(this.state.singleOppgjorDate).add(1, "days")
    });
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/singlerestonlinereport`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      // .then(handleErrors)
      .then((result) => result.json())
      .then(async (result) => {
        if (result.status === 404) {
          var onlineTotal = 0;
          await this.setState({
            currentOnlineTotal: 0
          });
          return onlineTotal;
        } else {
          await this.setState({
            currentOnlineTotal: result.data[0].onlineTotal
          });
          return result.data[0].onlineTotal;
        }
      })
      .catch((error) => console.log(error));
  };
  currentNettoCalculation = (
    singleOppgjor,
    singleOppgjorTaxes,
    currentOnlineTotal
  ) => {
    if (
      singleOppgjor &&
      singleOppgjor.total &&
      singleOppgjorTaxes &&
      singleOppgjorTaxes.totalTax
    ) {
      var t1 =
        (Number(singleOppgjorTaxes.totalTax) * Number(singleOppgjor.total)) /
        Number(100);
      var t2 =
        (Number(singleOppgjorTaxes.totalCash) * Number(singleOppgjor.cashTax)) /
        Number(100);
      var t3 =
        (Number(singleOppgjorTaxes.totalBank) * Number(singleOppgjor.bankTax)) /
        Number(100);

      var t4 =
        (Number(currentOnlineTotal) * Number(singleOppgjorTaxes.webTax)) /
        Number(100);
      var t5 =
        (Number(singleOppgjor.totalubetaltFaktura) *
          Number(singleOppgjor.fakturaTax)) /
        Number(100);
      var t6 = singleOppgjorTaxes.flatTotalAmt
        ? singleOppgjorTaxes.flatTotalAmt
        : 0;

      var t7 =
        (Number(singleOppgjorTaxes.coupanTax) *
          Number(singleOppgjor.totalCoupon)) /
        Number(100);
      var netto =
        Number(t1) +
        Number(t2) +
        Number(t3) +
        Number(t5) +
        Number(t6) +
        Number(t7);
      var nettoTax = (Number(netto) * 0.25).toFixed(2);
      var finalTotal = (Number(netto) + Number(nettoTax)).toFixed(2);
      this.setState({
        currentNetto: netto,
        currentNettoTax: nettoTax,
        currentfinalTotal: finalTotal
      });
    }
  };

  render() {
    const { restaurants, currentScreen, singleRestOppgjor } = this.state;
    const calTaxes = (memo) => {
      let bank = 0;
      if (
        this.state.singleOppgjor &&
        this.state.singleOppgjor.total &&
        this.state.singleOppgjorTaxes
      ) {
        bank = this.state.singleOppgjor.totalBank;
        // console.log(bank, this.state.singleOppgjor.totalBank, "vippsbabk");
      }

      let amt =
        this.state.singleOppgjor &&
        this.state.singleOppgjor.total &&
        this.state.singleOppgjorTaxes
          ? (this.state.singleOppgjorTaxes.totalTax *
              this.state.singleOppgjor.total) /
              100 +
            (this.state.singleOppgjorTaxes.cashTax *
              this.state.singleOppgjor.totalCash) /
              100 +
            (this.state.singleOppgjorTaxes.coupanTax *
              this.state.singleOppgjor.totalCoupon) /
              100 +
            (this.state.singleOppgjorTaxes.bankTax * bank) / 100 +
            (this.state.singleOppgjorTaxes.vippsTax *
              this.totalVippsWeb(this.state.singleRestOppgjor._id)) /
              100 +
            (this.state.singleOppgjorTaxes.treeDriveTax *
              this.totalTreeDrive(this.state.singleRestOppgjor._id)) /
              100 +
            (this.state.currentOnlineTotal *
              this.state.singleOppgjorTaxes.webTax) /
              100 +
            (this.state.singleOppgjorTaxes.fakturaTax *
            this.getTotalFaktura(this.state.branchId)
              ? this.getTotalFaktura(this.state.branchId)
              : 0) /
              100 +
            this.state.singleOppgjorTaxes.flatAmt
          : 0;

      console.log(
        this.totalTreeDrive(this.state.singleRestOppgjor._id),
        "total",
        this.state.singleOppgjorTaxes.treeDriveTax
      );
      if (memo) {
        amt = Number(amt * memo);
      }
      return amt;
    };
    const getRemainingAmt = () => {
      const rAmt =
        this.state.singleOppgjorTaxes &&
        this.state.singleOppgjorTaxes.remainingAmt
          ? Number(this.state.singleOppgjorTaxes.remainingAmt)
          : 0;
      const total =
        this.state.singleOppgjor && this.state.singleOppgjor.total
          ? Number(this.state.singleOppgjor.total).toFixed(2)
          : 0;
      const cTotal = Number(calTaxes(0.25)) + Number(calTaxes(0));
      const fTotal = Number(rAmt + cTotal - total).toFixed(2);
      return fTotal > 0 ? fTotal : 0;
    };
    if (currentScreen == 1) {
      return (
        <Fragment>
          <PageHeader className="site-page-header">
            <div style={{ float: "right" }}>
              <DatePicker
                onChange={(date, dateString) => (
                  this.getrestReport(date),
                  this.getrestqronlinereport(date),
                  this.getTdlReport(date),
                  this.getrestOnlineReport(date),
                  this.getRestaurants(),
                  this.getTaxes(date),
                  this.restReportUbetaltFakura(date),
                  this.getRemainingAmtApi(date),
                  this.getrestVippsOnlineReport(date),
                  this.getrestQRVippsOnlineReport(date)
                )}
                format="YYYY-MM-DD"
                placeholder="Select day"
              />
            </div>
          </PageHeader>
          <div className="columns is-centered" style={{ overflow: "scroll", height: '60vh' }}>
            <div className="row" style={{ width: "100%" }}>
              <div className="column">
                <table
                  style={{ width: "100%", overflow: "scroll" }}
                  className="table is-bordered"
                >
                  <thead style={{ position: "sticky", top: 0, backgroundColor: '#fff' }}>
                    <tr>
                      <th>S.No</th>
                      <th style={{ textAlign: "center" }}>Navn</th>
                      <th style={{ textAlign: "center" }}>A/c</th>
                      <th style={{ textAlign: "center" }}>T/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>K/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>B/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>W/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>F/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>CO/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>Vp/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>TD/salg</th>
                      <th style={{ textAlign: "center" }}>LG %</th>
                      <th style={{ textAlign: "center" }}>T/Flat</th>
                      <th style={{ textAlign: "center" }}>Netto</th>
                      <th style={{ textAlign: "center" }}>Moms (25%)</th>
                      <th style={{ textAlign: "center" }}>Totall</th>
                      <th style={{ textAlign: "center" }}>R/Amt</th>
                      <th style={{ textAlign: "center" }}>F/Totall</th>
                    </tr>
                  </thead>
                  <tbody>
                    {restaurants.map((rest, index) => {
                      return (
                        <tr key={rest._id}>
                          <td>{index + 1}</td>
                          <td>
                            <a
                              onClick={() =>
                                this.currentOppgjorData(rest, new Date())
                              }
                            >
                              {rest.name}
                            </a>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {rest.accountNumber}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalslg(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalTax(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalCash(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalCashTax(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalBank(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalBankTax(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalWeb(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalWebTax(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalFaktura(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalFakturaTax(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalCoupan(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalCopanTax(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalVippsWeb(rest._id)}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalVippsTax(rest._id)}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {this.renderTdlOrders(rest._id)}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {this.renderTreeDriveTax(rest._id)}
                          </td>

                          <td style={{ textAlign: "center" }}>
                            {this.renderTotalFlat(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.rederNetto(rest._id, "Netto")}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.rederNetto(rest._id, "NettoTax")}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.rederNetto(rest._id, "NettoTotalTaxPlus")}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.rederRemaining(rest._id)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.rederFinalTotal(rest._id)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else if (currentScreen == 2) {
      if (this.state.landing == false) {
        return <p>Loading...</p>;
      } else {
        return (
          <div>
            <PageHeader
              className="site-page-header"
              onBack={() => this.setState({ currentScreen: 1 })}
              title={singleRestOppgjor.name}
              subTitle={`Acc No. ${singleRestOppgjor.accountNumber}`}
              extra={[
                <DatePicker
                  onChange={(date, dateString) => (
                    this.setState({ singleOppgjorDate: date }),
                    this.currentOppgjorData(this.state.singleRestOppgjor, date)
                  )}
                  format="YYYY-MM-DD"
                  placeholder="Select day"
                />,
                <Button
                  onClick={() =>
                    this.openaddTaxModal(
                      this.state.singleRestOppgjor._id,
                      this.state.singleRestOppgjor.name,
                      this.state.singleRestOppgjor.taccountantEmail
                    )
                  }
                >
                  Add Taxes
                </Button>
              ]}
            />
            <div
              className="site-card-wrapper"
              style={{ background: "#efefef", padding: 20 }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="Total(Salg)" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr.
                      {this.state.singleOppgjor &&
                      this.state.singleOppgjor.total
                        ? this.state.singleOppgjor.total.toFixed(2)
                        : 0}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.totalTax}
                      %
                    </p>
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 20 }}>
                <Col span={4}>
                  <Card title="Kontant(Slag)" bordered={false}>
                    <p style={{ fontSize: 27, color: "blue",textDecoration: 'underline' }}>
                      Kr.{" "}
                      {this.state.singleOppgjor &&
                      this.state.singleOppgjor.totalCash
                        ? this.state.singleOppgjor.totalCash.toFixed(2)
                        : 0}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.cashTax}
                      %
                    </p>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card title="Kort(Salg)" bordered={false}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr.{" "}
                      {this.state.singleOppgjor &&
                      this.state.singleOppgjor.totalBank
                        ? this.state.singleOppgjor.totalBank.toFixed(2)
                        : 0}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.bankTax}
                      %
                    </p>
                  </Card>
                </Col>

                <Col span={4}>
                  <Card title="Web(Salg)" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr. {this.state.currentOnlineTotal.toFixed(2)}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.webTax}
                      %
                    </p>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card title="Faktura(Salg)" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr. {this.getTotalFaktura(this.state.branchId).toFixed(2)}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.fakturaTax}
                      %
                    </p>
                  </Card>
                </Col>

                <Col span={4}>
                  <Card title="Coupan(Salg)" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr.{" "}
                      {this.state.singleOppgjor &&
                      this.state.singleOppgjor.totalCoupon
                        ? this.state.singleOppgjor.totalCoupon.toFixed(2)
                        : 0}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.coupanTax}
                      %
                    </p>
                  </Card>
                </Col>
                <Col span={4}>
                  <Card title="Vipps(Salg)" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr. {this.totalVippsWeb(this.state.singleRestOppgjor._id)}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.vippsTax}
                      %
                    </p>
                  </Card>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: 20 }}>
                <Col span={4}>
                  <Card title="TREEDRIVE(Salg)" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr.{" "}
                      {this.totalTreeDrive(this.state.singleRestOppgjor._id)}
                    </p>
                    <p style={{ fontSize: 15, color: "grey" }}>
                      LG(%):{" "}
                      {this.state.singleOppgjorTaxes &&
                        this.state.singleOppgjorTaxes.treeDriveTax}
                      %
                    </p>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card title="Netto" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr.
                      {Number(calTaxes(0)).toFixed(2)}
                    </p>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card title="Netto(tax)" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr.
                      {Number(calTaxes(0.25)).toFixed(2)}
                    </p>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card title="Final Total" bordered={true}>
                    <p style={{ fontSize: 27, color: "blue" }}>
                      Kr.
                      {Number(
                        Number(calTaxes(0.25)) + Number(calTaxes(0))
                      ).toFixed(2)}
                    </p>
                  </Card>
                </Col>
                <Col span={5}>
                  <Card title="Remaining Amount" bordered={true}>
                    <p style={{ fontSize: 27, color: "red" }}>
                      Kr.
                      {getRemainingAmt()}
                    </p>
                  </Card>
                </Col>
              </Row>
            </div>
            <Modal
              title={this.state.editingRestName}
              visible={this.state.modalVisible}
              onOk={() => this.saveTaxes()}
              onCancel={this.handleCancel}
            >
              <Row style={{ alignContent: "center", alignItems: "center" }}>
                <Col span="24">
                  <label>
                    <b>Note:</b>{" "}
                    <span style={{ color: "red" }}>
                      Changes will apply from next working day.
                    </span>
                  </label>
                  <br />
                </Col>
              </Row>
              <Row style={{ alignContent: "center", alignItems: "center" }}>
                <Col span={12}>
                  <label>Total Tax</label>
                  <br />
                  <input
                    name="totalTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Total Tax"
                    value={this.state.totalTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
                <Col span={12}>
                  <label>Cash Tax</label>
                  <br />
                  <input
                    name="cashTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Cash Tax"
                    value={this.state.cashTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
              </Row>
              <Row
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <Col span={12}>
                  <label>Bank Tax</label>
                  <br />
                  <input
                    name="bankTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Bank Tax"
                    value={this.state.bankTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
                <Col span={12}>
                  <label>Web Tax</label>
                  <br />
                  <input
                    name="webTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Web Tax"
                    value={this.state.webTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
              </Row>

              <Row
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <Col span={12}>
                  <label>Faktura Tax</label>
                  <br />
                  <input
                    name="fakturaTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Faktura tax"
                    value={this.state.fakturaTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
                <Col span={12}>
                  <label>Flat Amount</label>
                  <br />
                  <input
                    name="flatAmt"
                    placeholder="Flat Amount"
                    value={this.state.flatAmt}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>

              <Row
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <Col span={12}>
                  <label>Coupon Tax</label>
                  <br />
                  <input
                    name="coupanTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Coupon tax"
                    value={this.state.coupanTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
                <Col span={12}>
                  <label>Vipps Tax</label>
                  <br />
                  <input
                    name="vippsTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Vipps tax"
                    value={this.state.vippsTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
              </Row>

              <Row
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <Col span={12}>
                  <label>Td Tax</label>
                  <br />
                  <input
                    name="tdTax"
                    // style={{ fontSize: 18 }}
                    placeholder="Coupon tax"
                    value={this.state.tdTax}
                    onChange={this.handleChange}
                  ></input>
                </Col>
              </Row>
            </Modal>
          </div>
        );
      }
    }
  }
}
