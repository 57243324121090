import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Radio, Select, Switch, notification, Icon, DatePicker } from "antd";
import "./Advertisement.css";
import axios from "axios";

const AddAdvertisement = (props) => {
  const [value, setValue] = useState("restaurant");
  const [value2, setValue2] = useState("picture");
  const [branchId, setBranchId] = useState();
  const [branchName, setBranchName] = useState();
  const [isAddOn, setIsAddOn] = useState(false);
  const [restaurants, setRestaurants] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [isActiveAdd, setIsActiveAdd] = useState(false);
  const [validation, setValidation] = useState(false);
  let [fileImage, setFileImage] = useState("");
  let [FileError, setFileError] = useState("");
  //  let [errors, setErrors] = useState(errorsObj);
  const { Option } = Select;

  useEffect(() => {
    getBranchList();
  }, [value]);

  const getBranchList = async () => {
    try {
      let result = await fetch(`${endpoint}/admin/restaurantList`, {
        method: "Get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })   
        .then((result) => result.json())
        .then((result) => {
          if (result.status === "failure") {
            console.log("THERE IS A FAULURE ");
          }
          if (result) {
            // setBranch(result.data);
            setRestaurants(result.data[0].data);
            setVendors(result.data[1].data);
            setRetailers(result.data[2].data);
            //console.log("data === ", restaurants)
          }
        });
    } catch (error) {
      console.log("something went wrong");
    }
  };
  const onChange = (e) => {
    setValue(e.target.value);
    //branchNameSelected();
  };
  // console.log("value is ", value)

  const branchNameSelected = (e) => {
    console.log("dfsdf", e);
    //let code = e.split(":")[1];
   // if()
    setBranchId(e);
  };

  //console.log("branch name is ", branchName)

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  function onChange2(checked) {
    console.log(`switch to ${checked}`);
    setIsAddOn(checked);
  }

  const onChange3 = (e) => {
    console.log("radio checked", e.target.value);
    setValue2(e.target.value);
  };

  // const checkValidation = () =>{
  //   if(value == "restaurant"){

  //   }else if( value == "vendor"){

  //   }else{

  //   }
    
  // }

  const handleLogoChange = (e) => {
  

    console.log(e.target.files[0]);
    if (e.target.files[0]) {
      //let errorsObj = { ...errors };
      let imageFile = e.target.files[0];
      let fileName = imageFile.name.toLowerCase();
      if (!fileName.match(/\.(jpg|jpeg|png|svg|gif|mp4)$/)) {
        setFileError("Please select valid image.")
        setValidation(false);
      }else if(!fileName.match(/\.(gif)$/) && value2 == "gif"){
        setFileError("Please select gif format advertisement.")
        setValidation(false);
      }else if(!fileName.match(/\.(mp4)$/) && value2 == "video"){
        setFileError("Please select video advertisement.")
        setValidation(false);
      }else if(!fileName.match(/\.(jpg|jpeg|png|svg)$/) && value2 == "picture"){
        setFileError("Please select picture format.")
        setValidation(false);
      }else{
        setValidation(true);
        setFileError("")
      }
      // let maxFileSize = 1024 * 10; // 10MB
      if (imageFile.size / 1024 / 1024  >  10) {
        // errorsObj.file.show = true;
        // errorsObj.file.message = "Image must smaller than 10MB!";
        //setErrors(errorsObj);
        notification.open({
          message: "Image must smaller than 10MB!",
          //icon: <Icon type="close-circle" style={{ color: "green" }} />,
          duration: 2,
        }); 
        console.log("file size is tooo large");
        setValidation(false);
      }
      setFileImage(imageFile);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // errorsObj.file.show = false;
        // errorsObj.file.message = "";
        // setIcon(reader.result);
      });
      reader.readAsDataURL(imageFile);
    }

    //submitAddvertisement(e);
  };
      let currDate = new Date();
  console.log("new Date();", new Date())

  const submitAddvertisement = async (e) => {


    console.log(validation , FileError)
   if(validation){
    const formData = new FormData();
    formData.append("image", fileImage, fileImage.name);
    formData.append("branchId", branchId ? branchId : "");
    formData.append("branchType", value ? value : "all");
    formData.append("contentType", value2);
    formData.append("isAdvertisementOn", isAddOn);
    // formData.append("timePeriod", timePeriod);
    formData.append("startDate", startDate ? startDate : currDate);
    formData.append("endDate", endDate ? endDate : currDate);
    // formData.append("branchName", branchName);
    const token = localStorage.getItem("token");

    // console.log("formData === ", timePeriod)

    const res = await axios.post(
      `${endpoint}/api/v1/admin/branch/addAdvertisement`,
      formData,
      {
        headers: {
          // "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    if(res.status == 200){
      notification.open({
            message: "Your advertisement saved successfully!",
            icon: <Icon type="check-circle" style={{ color: "green" }} />,
            duration: 3,
          });
          props.indexPage();
    }

   }else{
    setFileError("**please select valid data.");
   }
  };

  return (
    <div style={{ margin: "0 30%" }}>
      <form onSubmit={handleSubmit} className="form-horizontal box">
        <div className="field">
          <label className="label">Branch Type</label>
          <div className="control">
            <div className="field">
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={"all"}>All</Radio>
                <Radio value={"restaurant"}>Restaurant</Radio>
                <Radio value={"vendor"}>Vendor</Radio>
                <Radio value={"retailer"}>Retailer</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">Branch Name</label>
          <div className="control">
            <div style={{ width: "100%" }} className="select1">
              <Select
                required
                name="restaurant"
                placeholder="Please select"
                style={{ width: "100%" }}
                //value={setBranchName(taxType)}
                onChange={(e) => branchNameSelected(e)}
              >
                {value == "restaurant" &&
                  restaurants.map((item) => {
                    //console.log(item.name)
                    return (
                      <Option key={item._id} value={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
                {value == "vendor" &&
                  vendors.map((item) => {
                    //console.log(item.name)
                    return (
                      <Option key={item._id} value={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
                {value == "retailer" &&
                  retailers.map((item) => {
                    //console.log(item.name)
                    return (
                      <Option key={item._id} value={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
                  {value == "all" ? 
                      <Option value='disabled'>
                      </Option>
                    : ""
                  }
              </Select>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="bordered-special1">
            <label className="label">Keep Advertisement On</label>
            <div className="field-label is-normal"></div>
            <div className="field">
              <div className="control">
                <Switch
                  // value={tippermission}
                  // checked={tippermission}
                  onChange={onChange2}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <label className="label">Or select Date : </label>
          {/* <label >From : <span style={{paddingLeft:"160px"}}>To:</span></label> */}
        </div>
        <div className="d-flex field">
          <DatePicker
            // value={startDate}
            onChange={(date, dateString) => {
              // setDateFilter("byDay", date);

              let setDate = new Date(date);
              console.log("date", { setDate, dateString });
              setStartDate(setDate);
            }}
            format="YYYY-MM-DD"
            placeholder="From"
          />
          <DatePicker
            onChange={(date, dateString) => {
              // setDateFilter("byDay", date);

              let setDate = new Date(date);
              console.log("date", { setDate, dateString });
              setEndDate(setDate);
            }}
            format="YYYY-MM-DD"
            placeholder="To"
          />
        </div>
        <div className="field">
          <label className="label">Content Type</label>
          <Radio.Group onChange={onChange3} value={value2}>
            <Radio value={"picture"}>Picture</Radio>
            <Radio value={"gif"}>Gif</Radio>
            <Radio value={"video"}>Video</Radio>
          </Radio.Group>
        </div>
        <div>
          <input
            className="file-input"
            type="file"
            name="icon"
            // accept="image/*"
            onChange={handleLogoChange}
          />
        </div>
        { FileError.length > 0 ? <p className= "errorMsg">{FileError} </p> : ""}
        <button
          type="submit"
          style={{ marginTop: "15px", width: "100%" }}
          className="button is-primary"
          onClick={submitAddvertisement}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default AddAdvertisement;
