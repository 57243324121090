import React, { useEffect, useState } from "react";
import endpoint from "../../helpers/Endpoint";
import { Select } from "antd";

const CategoryForm = (props) => {
  const { Option } = Select;
  const category = props.category;
  let errorObj = {
    nameError: "",
    sCatTypeError: "",
    taxTypeError: "",
    CodeError: ""
  };
  const getShowTax = (taxes) => {
    let obj = [];
    if (taxes) {
      for (const [key, val] of taxes.entries()) {
        obj[key] = {
          code: setCodeToShow(val._id),
          key: val._id,
          name: val.taxName,
          isShow: checkIsShow(val._id),
          tax: val.taxValue
        };
      }
    }
    return obj;
  };
  const setCodeToShow = (val) => {
    if (taxType.length > 0) {
      for (var i = 0; i < taxType.length; i++) {
        if (taxType[i].tax == val) {
          return taxType[i].code;
        }
      }
    }
  };
  const checkIsShow = (val) => {
    var found = false;
    if (taxType.length > 0) {
      for (var i = 0; i < taxType.length; i++) {
        if (taxType[i].tax == val) {
          found = true;
          break;
        }
      }
    }
    return found;
  };
  const [name, setName] = useState(category.name ? category.name : "");
  const [sCatType, setSCatType] = useState(
    category.sCatType ? category.sCatType : ""
  );
  const [taxType, setTaxType] = useState(
    category.taxType && category.taxType.length > 0 ? category.taxType : []
  );
  const [errors, setErrors] = useState(errorObj);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showPlu, setShowPlu] = useState(getShowTax(props.branch.taxes));
  function handleErrors(result) {
    if (!result.ok) {
      throw Error(result.status);
    }
    return result;
  }
  const isEdit = Object.keys(category).length === 0 ? false : true;
  const handleSubmit = (event) => {
    event.preventDefault();
    let isError = false;
    if (name.trim() === "") {
      isError = true;
      errorObj.nameError = "Name is required";
    }
    if (sCatType === "") {
      isError = true;
      errorObj.sCatTypeError = "Category Type is required";
    }
    if (taxType.length > 0) {
      for (let i = 0; i < taxType.length; i++) {
        if (
          taxType[i].code &&
          taxType[i].code.trim() !== "" &&
          isNaN(taxType[i].code)
        ) {
          isError = true;
          errorObj.CodeError = "Code Should be in number";
        }
      }
    }
    if (isError) {
      setErrors(errorObj);
    } else {
      saveCategory();
    }
  };
  const handlePluTax = (e) => {
    let pluObj = showPlu;
    let taxty = [];
    for (let j = 0; j < showPlu.length; j++) {
      let flag = true;
      for (let i = 0; i < e.length; i++) {
        if (showPlu[j].key === e[i]) {
          
          taxty.push({ code: showPlu[j].code, tax: e[i], val: showPlu[j].tax,taxName:showPlu[j].name });
          pluObj[j].isShow = true;
          flag = false;
        }
      }
      if (flag) {
        pluObj[j].isShow = false;
      }
    }
    setShowPlu(pluObj);
    setTaxType(taxty);
  };
  const handlePluCode = (tx, e) => {
    let taxObj = [...taxType];
    let pluObj = [...showPlu];
    for (let i = 0; i < taxType.length; i++) {
      if (taxObj[i].tax === tx) {
        taxObj[i].code = e;
      }
      if (pluObj[i].key === tx) {
        pluObj[i].code = e;
      }
    }
    setShowPlu(pluObj);
    setTaxType(taxObj);
  };
  const getTaxValues = (obj) => {
    return obj.map((a) => a.tax);
  };
  const getTaxCode = (tax, obj) => {
    for (const a of obj) {
      if (a.tax == tax) {
        return a.code;
      }
    }
  };
  const saveCategory = () => {
    const url = isEdit
      ? `${endpoint}/api/v1/admin/branch/editCategories`
      : `${endpoint}/api/v1/admin/branch/addCategories`;
    const token = localStorage.getItem("token");
    const data = isEdit
      ? JSON.stringify({
          name,
          sCatType,
          taxType,
          branchId: props.branch._id,
          id: category._id
        })
      : JSON.stringify({
          name,
          sCatType,
          taxType,
          branchId: props.branch._id
        });
    fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log("THERE IS A FAULURE ");
        }
        if (result) {
          setIsLoaded(false);
          props.indexPage();
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div style={{ margin: "0 30%" }}>
      <form onSubmit={handleSubmit} className="form-horizontal box">
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <div className="field">
              <input
                className="input"
                type="text"
                name="name"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <p className="help is-danger">{errors.nameError}</p>
        </div>
        <div className="field">
          <label className="label">Category Type</label>
          <div className="control">
            <div style={{ width: "100%" }} className="select">
              <Select
                name="sCatType"
                value={sCatType}
                onChange={(e) => setSCatType(e)}
              >
                <Option value="">Select Type</Option>
                <Option value="KITCHEN">KITCHEN</Option>
                <Option value="BAR">BAR</Option>
              </Select>
            </div>
          </div>
          <p className="help is-danger">{errors.sCatTypeError}</p>
        </div>
        <div className="field">
          <label className="label">Tax Type</label>
          <div className="control">
            <div style={{ width: "100%" }} className="select">
              <Select
                name="taxType"
                mode="multiple"
                value={getTaxValues(taxType)}
                onChange={(e) => handlePluTax(e)}
              >
                {showPlu &&
                  showPlu.map((tax, idx) => {
                    return (
                      <Option key={idx} value={tax.key}>
                        {tax.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <p className="help is-danger">{errors.taxTypeError}</p>
        </div>
        <div className="columns">
          {showPlu &&
            showPlu.map((tax, idx) => {
              if (tax.isShow) {
                return (
                  <div key={idx} className="column">
                    <div className="field">
                      <label className="label">{tax.name} Code</label>
                      <div className="control">
                        <div className="field">
                          <input
                            className="input"
                            type="text"
                            name="plucode"
                            placeholder="Enter Code"
                            value={getTaxCode(tax.key, taxType)}
                            onChange={(e) =>
                              handlePluCode(tax.key, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        {errors.CodeError ? (
          <p
            style={{ marginTop: "-20px", marginBottom: "20px" }}
            className="help is-danger"
          >
            {errors.CodeError}
          </p>
        ) : (
          ""
        )}
        <button
          type="submit"
          style={{ width: "100%" }}
          className="button is-primary"
        >
          {isEdit ? "Update" : "Save"}
        </button>
      </form>
    </div>
  );
};
export default CategoryForm;
