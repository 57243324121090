import React, { useState,useEffect } from 'react';
import axios from 'axios';
import endpoint from "../../helpers/Endpoint";
import moment from "moment";
import { Table } from 'antd';
import { Select } from 'antd';
import  Column from "./dataColumn.js";
import { Popconfirm, message } from 'antd';

const TableData=(props)=>{
    const deleteInvoice=async(record)=> {
        try{
            console.log("inside the delete confirm---->",record.tableId);
            const tableId=record.tableId;
            // setIsLoading(true);
            const token = localStorage.getItem("token");
            // let {startDate, endDate}=setDefaultDates(date1);
            // console.log("inside the getAllInProcessInvoices selectedRestaurantId--->",selectedRestaurantId)
            const result = await axios.post(
                `${endpoint}/api/v1/admin/inprocessinvoices/deleteunpaidcartinvoice`,
                {
                  tableId
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": token
                  }
                }
              );
            //   setInProcessInvoices(result.data.data.invoiceData);
            //   setIsLoading(false);
            props.setReload(!(props.reload))
            message.success('Invoice deleted Successfully');
        }catch(err){
            message.error("Cannot delete the invoice")
        }
       
      }
    
    const markAsPaid=async(record)=>{
        try{
            console.log("inside the mark as paid function---->",record.tableId);
            const tableId=record.tableId;
            const branchId=record.branchId._id;
            console.log("inside the mark as paid branchId---->",branchId);
            // setIsLoading(true);
            const token = localStorage.getItem("token");
            // let {startDate, endDate}=setDefaultDates(date1);
            // console.log("inside the getAllInProcessInvoices selectedRestaurantId--->",selectedRestaurantId)
            const result = await axios.post(
                `${endpoint}/api/v1/admin/inprocessinvoices/changepaymentstatuscartinvoice`,
                {
                  tableId,
                  branchId
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": token
                  }
                }
              );
            //   setInProcessInvoices(result.data.data.invoiceData);
            //   setIsLoading(false);
            props.setReload(!(props.reload));
            console.log("result in marks as paid------>",result);
            if(result.data.status!=(200 || 202)){
              message.error(result.data.message)
            }
            else{
              message.success(result.data.message);
            }
           
        }catch(err){
            message.error("Cannot mark as paid the invoice")
        }
    }
    
    return(
        <>
        <Table bordered loading={props.isLoading} dataSource={props.inProcessInvoices} columns={Column(deleteInvoice,markAsPaid)}></Table>
        </>
    )
}

export default TableData;