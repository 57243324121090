import { Button, Modal } from "antd";
import React, { useState } from "react";

const CustomModalComponent = (props) => {
  const [modal2Visible, setModal2Visible] = useState(props.isShowPriceCompoent);

  let onCancelClicked = () => {
    props.closePriceModal();
  };
  return (
    <>
      <Modal
        title={props.title}
        centered
        visible={modal2Visible}
        onOk={props.addPriceHandeler}
        onCancel={onCancelClicked}
      >
        {props.children}
      </Modal>
    </>
  );
};

export { CustomModalComponent };
