import React from "react";
import { Button ,Popconfirm, message } from 'antd';
  function cancel(e) {
        console.log(e);
        message.error('Click on No');
      }
const Column=(deleteInvoice,markAsPaid)=>{
   return [
        {
            title:"Invoice No.",
            dataIndex:"invoiceNumber",
            key:"1"
        },
        {
            title:"Restaurant",
            dataIndex:"branchId.name",
            key:"6"
        },
        {
            title:"Vendor Name",
            key:"2",
            render:(text,record)=>(
                <h1>
                    {record.transaction.vendorName}
                </h1> 
            )
        },
        {
            title:"Amount",
            key:"2",
            render:(text,record)=>(
                <h1>
                    {record.bank}
                </h1> 
            )
        },
        {
            title:"Paid On",
            key:"3",
            render:(text,record)=>(
                <>
                 <h1>{new Date(record.paidOn).toLocaleString()}</h1>
                </>
               
            )
            

        },
        {
            title:"Mark Paid",
            key:"4",
            render:(text,record)=>(
                // <Button type="primary">Mark as Paid</Button>
                // <Button type="primary">Delete</Button>
                <Popconfirm
                 title="Are you sure to mark it as paid?"
                    onConfirm={()=>markAsPaid(record)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
  >
          <Button
            type="primary"
            style={{ background: "#389e0d", borderColor: "#389e0d" }}
          >
            Mark as Paid
          </Button>
                </Popconfirm>
                
            )
        },
        {
            title:"Delete",
            key:"5",
            render:(text,record)=>(
                <Popconfirm
                 title="Are you sure to delete it?"
                    onConfirm={()=>deleteInvoice(record)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
  >
          <Button type="danger">Delete</Button>
                </Popconfirm>
                
            )
        }
    ]
}

export default Column;